import { Address, VenueAddress } from "../api/models/address";

export default class AddressHelper{

    static getReadableNormalAddress(address: Address): string{
        let readableAddress = address.line1 + ' '
        if(address.line2) readableAddress += address.line2 + ' '
        if(address.line3) readableAddress += address.line3 + ' '
        readableAddress += address.zip_code + ' ' + address.city
        return readableAddress
    }

    static getReadableAddressFromVenue(venue_address: VenueAddress): string{
        if(venue_address.venue_details?.venue_name && venue_address.venue_details.venue_name !== '') 
            return venue_address.venue_details.venue_name
        else 
            return this.getReadableNormalAddress(venue_address.address)
    }
}