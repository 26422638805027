import {CatalogItemCategoryMsg, CatalogItemMsg} from "../../proto_generated/catalog/catalog_pb";
import {ImageMsg} from "../../proto_generated/type/media_pb";
import {AccessType, Address} from "../api/models/address";
import {TimeSlot} from "../api/models/datetime";
import {ItemCategory, ItemImage, PricedItem} from "../api/models/item";
import {PRODUCT_TYPE} from "../utils/consts";
import {getItemPriceForCurrentQuantity} from "../redux/event/EventHelper";
import {LocationFormInfo} from "../ui/eventDataForm/EventDataForm";
import AddressHelper from "../utils/AddressHelper";
// _____________________________________________________________________________________________________ MARKET CATEGORY
export interface MarketCategoryType{
    uuid: string
    slug: string
    content: MarketCategoryContent
    catalogItemCategoryUuids: string[] // List of all CatalogItemCategory uuids within that MarketCategory.
}

export interface MarketCategoryContent {
    name: string;
    title: string;
    description: string;
    shortDescription: string;
    detailPageTitle: string;
    detailPageDescription: string;
    detailPageDescriptionV2_1: MarketCategoryContentDescriptionV2_1[]; // See https://www.figma.com/file/GX8TXAP88l4K5upvmnNBv1/Sedeo-v2?type=design&node-id=1-526&mode=design&t=tooJYNAsrmsNrpDx-0 to understand usage
    headTags: TagData[];
    marketSubCategoriesSlug: string[];
    marketShortSubCategories?: MarketShortSubCategoryType[];
    isPresentable: boolean;
    order: number;
}

export interface MarketCategoryContentDescriptionV2_1 {
    h2: string;
    p: string;
}

export interface MarketShortSubCategoryType {
    slug: string,
    name: string
}

// _________________________________________________________________________________________________ MARKET SUB CATEGORY
export interface MarketSubCategoryType{
    uuid: string
    slug: string
    content: MarketSubCategoryContent
}
export interface MarketSubCategoryContent {
    name: string;
    title: string;
    description: string;
    descriptionV2_1: MarketSubCategoryContentDescriptionV2_1[];
    shortDescription: string;
    subCategoryH1: string;
    backEndCategoryUuid: string;
    itemOrders: {itemUuid: string, order: number}[]
    headTags: TagData[];
}


export interface MarketSubCategoryContentDescriptionV2_1 {
    h2: string;
    p: string;
}
// _________________________________________________________________________________________________________ MARKET ITEM


export interface MarketItem{
    uuid: string
    catalogItemUuid: string // uuid of the corresponding CatalogItemMsg
    slug: string
    content: MarketItemContent
}

export interface MarketItemContent {
    marketCategorySlug: string
    marketSubCategorySlug: string;
    headTags: TagData[];
}


// ___________________________________________________________________________________________________ MARKET COLLECTION
export interface MarketCollection{
    uuid: string
    slug: string
    content: MarketCollectionContent
}

export interface MarketCollectionContent {
    marketCategorySlug: string; // See https://www.notion.so/sedeo/Marketplace-CMS-Content-55bcb0dcf0b441fabecdd6821d849535?pvs=4#05a2b81d14134c88b2d15652a5224ee8
    marketSubCategorySlug: string;
    itemCategoryUuid: string;
    name: string;
    description: string;
    images: ImageMsg.AsObject[];
    itemOrders: {itemUuid: string, order: number}[]
    headTags: TagData[];
}

// _____________________________________________________________________________________________________________ PRODUCT

export interface SingleProduct {
    type: PRODUCT_TYPE.SINGLE;
    data: PricedItem;
    marketItem: MarketItem
    itemDescription: string
}

export interface CollectionProduct {
    type: PRODUCT_TYPE.COLLECTION;
    marketCollection: MarketCollection;
    items: SingleProduct[]
}

export type ProductItemType = SingleProduct | CollectionProduct

// ______________________________________________________________________________________________________ META DATA TAGS
export interface TagData {
    type: string;
    attributes: Attribute[];
    content: string;
}

export type Attribute = {
    name: string;
    value: string;
};


//#region _____________________________________________________________________________________________________ BANKROLL
export enum BankrollStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    PENDING = 'pending'
}
//#endregion



export interface CartType{
    items: ProductItemType[]
}



//#region transformation methods _______________________________________________________________________ Data transformation methods to adapt to the new models of the new Rest API. Just for now, until we completely get rid of the GRPC



export const createProductType = (catalogItem: CatalogItemMsg.AsObject, marketItem: MarketItem): SingleProduct => {
    return{
        data: transformCatalogItemToPricedItem(catalogItem, marketItem),
        marketItem: marketItem,
        itemDescription: catalogItem.description,
        type: PRODUCT_TYPE.SINGLE
    }
}

const transformCatalogItemToPricedItem = (catalogItem: CatalogItemMsg.AsObject, marketItem: MarketItem): PricedItem => {
    return {
        uuid: catalogItem.uuid,
        name: catalogItem.name,
        quantity: 0,
        images: transformImageMsgToItemImage(catalogItem.imagesList),
        categories: transformCatalogItemCategoryMsgToItemCategory(catalogItem.categoriesList),
        exc_tax_unit_price: getItemPriceForCurrentQuantity(1, catalogItem.pricesList),
        exc_tax_total_price: getItemPriceForCurrentQuantity(1, catalogItem.pricesList)
    }
}

const transformImageMsgToItemImage = (images: ImageMsg.AsObject[]): ItemImage[] => {
    return images.map(img => {
        return {
            file_key: img.url,
            alt: img.alt,
            order: img.order
        }
    })
}

const transformCatalogItemCategoryMsgToItemCategory = (categories: CatalogItemCategoryMsg.AsObject[]): ItemCategory[] => {
    return categories.map(category => {
        return {
            id: category.uuid,
            name: category.name
        }
    })
}

//#endregion ___________________________________________________________________________ End of legacy transformation methods




export interface OrderShippingInfo {

    isCurbside: boolean;
    isInsideDeliveryType: AccessType;
    distanceBetweenTruckAndDelivery: number
    floor: number; // Which floor
    isInsideAccessibilityPainLess: boolean;

    deliveryTimeSlot: TimeSlot|undefined;
    returnTimeSlot: TimeSlot|undefined;

    billingLine1: string,
    billingLine2: string,
    billingLine3: string,
    billingCity: string,
    billingZipCode: string,
}

export interface OrderInformationInfo {
    locationName: string;
    roomDetails: string;
    contactFirstName: string;
    contactLastName: string;
    company_name: string;
    phone_number: string;
    digicode: string;
    buildingOrDoorName: string;
    needLicensePlate: boolean;
    isReturnContactDifferent: boolean;
    returnContactPhone: string;
    returnContactLastName: string;
    returnContactFirstName: string;
    miscDeliveryInfo: string | null;
    isBillingAddressDifferent: boolean;
    billingAddressLabel: string;
    billingAddress: LocationFormInfo | undefined;
}

export const transformAddressToLocationFormInfo =(address: Address): LocationFormInfo =>{
    return {
        address: address,
        addressLabel: AddressHelper.getReadableNormalAddress(address),
        googleSelectedPlace: null,
        lat: address.coordinates.latitude,
        lng: address.coordinates.longitude,
    }
}


export interface SpatialDataResult{
    spatialToken: string,
    defaultMarketRedirectionUrl: string
}