import {PriceRange} from "../../../proto_generated/type/pricing_pb";
import {CartResponse} from "../../api/carts/CartApiDataModel";
import {getAddressAccessType} from "../../api/models/address";
import {CartType, OrderInformationInfo, OrderShippingInfo, transformAddressToLocationFormInfo} from "../../typing/types";
import MarketItemAPIHelper from "../../utils/api/cms/MarketItemAPIHelper";
import ProductsAPIHelper from "../../utils/api/products/ProductsAPIHelper";
import RoutesHelper, {PRODUCT_TYPE} from "../../utils/consts";
import {EventDataType} from "./EventDataType";
import {EventState} from "./event.reducer";
import {OrderingStep} from "../../utils/AmplitudeHelper";
import AddressHelper from "../../utils/AddressHelper";
import BugsnagHelper from "../../utils/BugsnagHelper";

/**
 * Return, for a given itemQuantity in the cart, the price of the next item if the user was to add it in its cart.
 * /!\ We now assume all prices retrieved from the back-end are in cents across the platform. Because the catalog logic
 * remains on the client, we need convert it to cents. That's why we multiply the price by 100 /!\
 * @param itemQuantity
 * @param priceRanges
 */
export function getItemPriceForCurrentQuantity(itemQuantity: number, priceRanges: PriceRange.AsObject[]): number{
    // Make sure the prices are sorted by lowest to highest
    let pricingOrderedByPrice = priceRanges.sort((item1, item2)=> item1.price - item2.price)
    let priceRange = pricingOrderedByPrice.find(item => item.start <= itemQuantity && item.end >= itemQuantity)
    if(priceRange){
        return priceRange.price * 100 / priceRange.packSize
    }else{
        return pricingOrderedByPrice[pricingOrderedByPrice.length - 1].price * 100 / pricingOrderedByPrice[pricingOrderedByPrice.length - 1].packSize
    }
}


enum GetEventStateFromCartErrors {
    GENERIC
}
export async function getEventStateFromCart(cart: CartResponse, onSuccess: (state: EventState, spatialToken: string, defaultMarketRedirectionUrl: string) => void, onError: (error: GetEventStateFromCartErrors) => void){

    try {
        if (cart.venue_address === undefined
            || cart.venue_address.address.coordinates === undefined || cart.venue_address.address === undefined
            || cart.transport_schedule.delivery === undefined || cart.transport_schedule.delivery.start === undefined
            || cart.transport_schedule.retrieval === undefined || cart.transport_schedule.retrieval.start === undefined) {
            onError(GetEventStateFromCartErrors.GENERIC)
            return
        }

        const spatialToken = await ProductsAPIHelper.getSpatialToken(cart.venue_address.address.coordinates.latitude, cart.venue_address.address.coordinates.longitude,
            1000)

        let defaultMarketRedirectionUrl: string
        try {
            const availableProduct = await ProductsAPIHelper.getAvailableProduct(spatialToken)
            defaultMarketRedirectionUrl = RoutesHelper.getSubCategoryRouteByPathName(availableProduct.marketItem.content.marketCategorySlug, availableProduct.marketItem.content.marketSubCategorySlug)
        }catch (e) {
            BugsnagHelper.captureMessage(`getEventStateFromCart could not find available product for ${spatialToken} | ${JSON.stringify(e)}`)
            // In case no items are available for the event, redirect to the home
            defaultMarketRedirectionUrl= '/'
        }

        let eventData: EventDataType = {
            cart: cart,
            googleSelectedPlace: null,
            SPToken: spatialToken,
            maxOrderingStep: OrderingStep.NONE,
            defaultMarketRedirectionUrl: defaultMarketRedirectionUrl,
        }

        let orderShippingInfo: OrderShippingInfo = {
            isCurbside: cart.venue_address.handling_details.curbside,
            isInsideDeliveryType: getAddressAccessType(cart.venue_address.handling_details.floor, cart.venue_address.handling_details.has_elevator),
            distanceBetweenTruckAndDelivery: cart.venue_address.handling_details.handling_distance,
            floor: cart.venue_address.handling_details.floor, // Which floor
            isInsideAccessibilityPainLess: cart.venue_address.handling_details.has_flat_access,

            deliveryTimeSlot: cart.transport_schedule.delivery,
            returnTimeSlot: cart.transport_schedule.retrieval,

            billingLine1: cart.venue_address.address.line1 ?? '',
            billingLine2: cart.venue_address.address.line2 ?? '',
            billingLine3: cart.venue_address.address.line3 ?? '',
            billingCity: cart.venue_address.address.city ?? '',
            billingZipCode: cart.venue_address.address.zip_code ?? '',
        }

        let orderShippingDetails: OrderInformationInfo = {
            locationName: cart.venue_address.venue_details.venue_name,
            roomDetails: cart.venue_address.venue_details.room_name,
            contactFirstName: cart.event_contact.delivery.firstname,
            contactLastName: cart.event_contact.delivery.lastname,
            phone_number: cart.event_contact.delivery.phone_number,
            company_name: cart.details.company_name,
            digicode: cart.venue_address.venue_details.digicode,
            buildingOrDoorName: cart.venue_address.venue_details.building_name,
            needLicensePlate: cart.venue_address.venue_details.licence_plate_required,
            isReturnContactDifferent: cart.event_contact.retrieval !== null,
            returnContactPhone: cart.event_contact.retrieval !== null ? cart.event_contact.retrieval.phone_number : '',
            returnContactLastName: cart.event_contact.retrieval !== null ? cart.event_contact.retrieval.lastname : '',
            returnContactFirstName: cart.event_contact.retrieval !== null ? cart.event_contact.retrieval.firstname : '',
            miscDeliveryInfo: cart.details.misc,
            isBillingAddressDifferent: cart.billing_address.google_place_id !== cart.venue_address.address.google_place_id,
            billingAddressLabel: AddressHelper.getReadableNormalAddress(cart.billing_address),
            billingAddress: transformAddressToLocationFormInfo(cart.billing_address)
        }

        let marketItemsToFetch: string[] = []
        cart.products.items.forEach((entry) => {
            if (entry.uuid) {
                marketItemsToFetch.push(entry.uuid)
            }
        })

        // There might be cases where the user hasn't added items to his cart yet
        if (marketItemsToFetch.length > 0) {
            MarketItemAPIHelper.getMarketItemsByOwners(marketItemsToFetch).then(marketItems => {
                let cartType: CartType = {
                    items: cart.products.items.flatMap((entry) => {
                        if (entry === undefined) {
                            return []
                        } else {
                            return {
                                type: PRODUCT_TYPE.SINGLE,
                                data: entry,
                                marketItem: marketItems.find(marketItem => marketItem.catalogItemUuid === entry.uuid)!,
                                itemDescription: '' // Description not needed
                            }
                        }
                    })
                }

                onSuccess({
                    currentEvent: eventData,
                    shippingInfo: orderShippingInfo,
                    shippingDetails: orderShippingDetails,
                    cart: cartType,
                    marketCategoriesWithItems: [],
                }, spatialToken, defaultMarketRedirectionUrl)

            }).catch(err => {
                onError(GetEventStateFromCartErrors.GENERIC)
            })

        } else {
            onSuccess({
                currentEvent: eventData,
                shippingInfo: orderShippingInfo,
                shippingDetails: orderShippingDetails,
                cart: {items: []},
                marketCategoriesWithItems: [],
            }, spatialToken, defaultMarketRedirectionUrl)
        }
    }catch (e) {
        BugsnagHelper.captureMessage(`getEventStateFromCart error: ${JSON.stringify(e)}`)
        onError(GetEventStateFromCartErrors.GENERIC)
    }

}