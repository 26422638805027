import ApiHelper, {HTTP_VERBS} from "../ApiHelper";
import {GetOrCreateAddress, GetOrCreateAddressResponse} from "./dto/GetOrCreateAddress";
import {AddressesApiContract} from "./AddressesApiContract";
import {ListVenuesFromAddressResponse} from "./dto/ListVenuesFromAddress";
import CartApi from "../carts/CartApi";

export default class AddressesApi implements AddressesApiContract{
    static readonly ADDRESSES_URL = '/addresses/';
    static readonly VENUES_URL = '/venues/';

    public async getOrCreateAddress(request: GetOrCreateAddress): Promise<GetOrCreateAddressResponse> {
        return (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${AddressesApi.ADDRESSES_URL}`, request)).json()
    }

    public async listVenuesFromAddress(addressId: string): Promise<ListVenuesFromAddressResponse> {
        return (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.GET,`${AddressesApi.ADDRESSES_URL}${addressId}/venues`)).json()
    }

}