import {SessionActionType} from './session.types';
import {SessionActionContract} from './session.actions-contract';
import {GetUserResponse} from "../../api/me/dto/GetUser";

export enum USER_CONNECTION_STATUS {
    LOGGED_IN = 'logged_in',
    LOGGED_OFF = 'logged_off',
}
export interface LoggedInUser {
    status: USER_CONNECTION_STATUS.LOGGED_IN
    user: GetUserResponse
}

export interface LoggedOffUser {
    status: USER_CONNECTION_STATUS.LOGGED_OFF
}

export type UserSession = LoggedInUser | LoggedOffUser

export interface SessionState {
    userSession: UserSession;
}

const initialState: SessionState = {
    userSession: {
        status: USER_CONNECTION_STATUS.LOGGED_OFF
    },
};

const sessionReducer = (state: SessionState = initialState, action: SessionActionContract) => {
    switch (action.type) {
        case SessionActionType.SIGN_IN_SUCCESS: {
            const {user} = action.payload;
            // State
            return {
                ...state,
                userSession: {
                    status: USER_CONNECTION_STATUS.LOGGED_IN,
                    user: user
                } as LoggedInUser
            }
        }
        case SessionActionType.LOG_OUT: {
            // State
            return {
                ...state,
                userSession: {
                    status: USER_CONNECTION_STATUS.LOGGED_OFF
                } as LoggedOffUser
            }
        }
        default:
            return state;
    }
};


export default sessionReducer;
