import { BlobGetMsg, BlobListMsg, BlobMsg } from "../../../../proto_generated/marketing/marketing_pb";
import { MarketingClient } from "../../../../proto_generated/marketing/MarketingServiceClientPb";
import { PageRequestMsg } from "../../../../proto_generated/type/pager_pb";
import { CollectionProduct, createProductType, MarketCollection, MarketCollectionContent, SingleProduct } from "../../../typing/types";
import JsonSchemaValidator, { JsonSchemaValidatorType } from "../../cms/JsonSchemaValidator";
import { PRODUCT_TYPE } from "../../consts";
import ProductsAPIHelper from "../products/ProductsAPIHelper";
import MarketItemAPIHelper from "./MarketItemAPIHelper";
import FilterMsg = BlobListMsg.FilterMsg;
import BlobKind = BlobMsg.BlobKind;
import ItemsOrderHelper from "../../../ui/market/ItemsOrderHelper";


export enum GetMarketCollectionByItemCategoryUuidError{
    CONNECTION_ERROR, NO_MARKET_COLLECTION_FOUND, TOO_MANY_MARKET_COLLECTIONS_FOUNDS
}

export enum GetMarketCollectionBySlugError{
    CONNECTION_ERROR, NO_MARKET_COLLECTION_FOUND
}

export enum GetCollectionProductFromSlugError {
    CONNECTION_ERROR, NO_MARKET_COLLECTION_FOUND, COULD_NOT_FIND_ITEMS, COULD_NOT_FIND_MARKET_ITEMS
}

export default class MarketCollectionApiHelper {

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getAllMarketCollections(withDecodingErrorCheck: boolean = true): Promise<MarketCollection[]>{
        return new Promise<MarketCollection[]>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(999999)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.COLLECTION])
            blobListMsg.setFilter(filterMsg)

            let marketingClient = new MarketingClient(process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!)
            marketingClient.list(blobListMsg, {}, (err, res) => {
                if(res){
                    let marketCollections: MarketCollection[] = []
                    let errors: string[] = []
                    res.getBlobsList().forEach((blob) => {
                        let decodedContent = JSON.parse(blob.getContent());
                        // Check that JSON has the correct MarketCollection format
                        let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_COLLECTION, decodedContent) : undefined
                        if(decodingErrors){
                            errors.push(decodingErrors)
                        }else {
                            marketCollections.push({
                                uuid: blob.getUuid(),
                                slug: blob.getSlug(),
                                content: {
                                    marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                    marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                    itemCategoryUuid: decodedContent.itemCategoryUuid ?? '',
                                    name: decodedContent.name ?? '',
                                    description: decodedContent.description ?? '',
                                    images: decodedContent.images ?? '',
                                    itemOrders: decodedContent.itemOrders ?? '',
                                    headTags: decodedContent.headTags ?? ''
                                },
                            })
                        }
                    })
                    if(errors.length > 0){
                        reject(errors.join(', '))
                    }else {
                        resolve(marketCollections)
                    }
                }else{
                    reject(err)
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketCollectionBySlug(slug: string, withDecodingErrorCheck: boolean = true): Promise<MarketCollection>{
        return new Promise<MarketCollection>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(slug)
            let marketingClient = new MarketingClient(process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!)
            marketingClient.get(blobGetMsg, {}, (err, res) => {
                if(res){
                    let blob = res
                    let decodedContent: MarketCollectionContent = JSON.parse(blob.getContent());
                    // Check that JSON has the correct MarketCollection format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_COLLECTION, decodedContent) : undefined
                    if(decodingErrors){
                        reject(decodingErrors)
                    }else {
                        resolve({
                            uuid: blob.getUuid(),
                            slug: blob.getSlug(),
                            content: {
                                marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                itemCategoryUuid: decodedContent.itemCategoryUuid ?? '',
                                name: decodedContent.name ?? '',
                                description: decodedContent.description ?? '',
                                images: decodedContent.images ?? '',
                                itemOrders: decodedContent.itemOrders ?? '',
                                headTags: decodedContent.headTags ?? ''
                            },
                        })
                    }
                }else{
                    if(err.message === "marketing blob not found") {
                        reject(GetMarketCollectionBySlugError.NO_MARKET_COLLECTION_FOUND)
                    }else{
                        reject(GetMarketCollectionBySlugError.CONNECTION_ERROR)
                    }
                }
            })
        })
    }

    /**
     * /!\ An ItemCategory can only have 1 corresponding MarketCollection. In case no MarketCollection is returned we need to tell the user to create one,
     * but if 2 or more MarketCollection are returned it means something wrong happened and it has to be resolved by keeping only one.
     * This is up to the caller to show relevant UI by reading the different errors returned in the catch block /!\
     *
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketCollectionByItemCategoryUuid = (itemCategoryUuid: string, withDecodingErrorCheck: boolean = true): Promise<MarketCollection> => {
        return new Promise<MarketCollection>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(50)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.COLLECTION])
            filterMsg.setOwnerUuidsList([itemCategoryUuid])
            blobListMsg.setFilter(filterMsg)

            let marketingClient = new MarketingClient(process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!)
            marketingClient.list(blobListMsg, {}, (err, res) => {
                if(res){
                    if(res.getBlobsList().length === 0){
                        reject(GetMarketCollectionByItemCategoryUuidError.NO_MARKET_COLLECTION_FOUND)
                    }else if(res.getBlobsList().length > 1){
                        reject(GetMarketCollectionByItemCategoryUuidError.TOO_MANY_MARKET_COLLECTIONS_FOUNDS)
                    }else{
                        let blob = res.getBlobsList()[0]
                        let decodedContent: MarketCollectionContent = JSON.parse(blob.getContent());
                        // Check that JSON has the correct MarketCollection format
                        let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_COLLECTION, decodedContent) : undefined
                        if(decodingErrors){
                            reject(decodingErrors)
                        }else {
                            resolve({
                                uuid: blob.getUuid(),
                                slug: blob.getSlug(),
                                content: {
                                    marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                    marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                    itemCategoryUuid: decodedContent.itemCategoryUuid ?? '',
                                    name: decodedContent.name ?? '',
                                    description: decodedContent.description ?? '',
                                    images: decodedContent.images ?? '',
                                    itemOrders: decodedContent.itemOrders ?? '',
                                    headTags: decodedContent.headTags ?? ''
                                },
                            })
                        }
                    }
                }else{
                    reject(GetMarketCollectionByItemCategoryUuidError.CONNECTION_ERROR)
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getCollectionProductFromSlug(slug: string, SPToken: string|undefined, itemOrders: {itemUuid: string, order: number}[], withDecodingErrorCheck: boolean = true): Promise<CollectionProduct>{
        // implement the SPToken logic here..
        return new Promise<CollectionProduct>((resolve, reject) => {

            this.getMarketCollectionBySlug(slug, withDecodingErrorCheck).then((marketCollection: MarketCollection) => {
                ProductsAPIHelper.listCatalog([marketCollection.content.itemCategoryUuid], SPToken).then((catalogItems) => {
                    if(catalogItems.length === 0){
                        reject([])
                    }else {
                        MarketItemAPIHelper.getMarketItemsByOwners(catalogItems.map(item => item.getUuid()), withDecodingErrorCheck).then(marketItems => {
                            let singleProducts: SingleProduct[] = []

                            catalogItems.forEach((itemMsg) => {
                                let marketItem = marketItems.find(marketItem => marketItem.catalogItemUuid === itemMsg.getUuid())
                                if(marketItem){
                                    singleProducts.push(createProductType(itemMsg.toObject(), marketItem))
                                }
                            })

                            if (singleProducts.length !== catalogItems.length){
                                // If not all ItemMsg has a corresponding MarketItem, show an error telling the user to add the MarketCollection first
                                catalogItems.filter(item => !singleProducts.some(product => product.data.uuid === item.getUuid()))
                                reject("Not all itemsMsg have corresponding marketItems: "+catalogItems.map(item => item.getName()).join(', '))
                            }else {
                                resolve({
                                    type: PRODUCT_TYPE.COLLECTION,
                                    marketCollection: marketCollection,
                                    items: ItemsOrderHelper.sortItems(itemOrders, singleProducts) as SingleProduct[]
                                })
                            }
                        }).catch(err => {
                            reject(GetCollectionProductFromSlugError.COULD_NOT_FIND_MARKET_ITEMS)
                        })
                    }
                }).catch(err => {
                    reject(GetCollectionProductFromSlugError.COULD_NOT_FIND_ITEMS)

                })
            }).catch((error: GetMarketCollectionBySlugError) => {
                switch(error){
                    case GetMarketCollectionBySlugError.CONNECTION_ERROR:
                        reject(GetCollectionProductFromSlugError.CONNECTION_ERROR)
                        break;
                    case GetMarketCollectionBySlugError.NO_MARKET_COLLECTION_FOUND:
                        reject(GetCollectionProductFromSlugError.NO_MARKET_COLLECTION_FOUND)
                        break;
                    default: const _exhaustiveCheck: never = error;
                }
            })

        })

    }

}