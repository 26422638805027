import { login, logout, requestPasswordReset, resetPassword, restoreSession, session, setCookieStore, setHost, signup } from "keratin-authn";
import { Credentials, KeratinError } from "keratin-authn/dist/types";
import AmplitudeHelper from "../utils/AmplitudeHelper";
import BugsnagHelper from "../utils/BugsnagHelper";

export enum KeratinErrors{
    Missing, Taken, FormatInvalid, Insecure, Failed, Locked, Expired, NotFound, InvalidOrExpired, Unknown
}
function getKeratinError(error: string): KeratinErrors{
    switch(error){
        case 'MISSING': return KeratinErrors.Missing;
        case 'TAKEN': return KeratinErrors.Taken;
        case 'FORMAT_INVALID': return KeratinErrors.FormatInvalid;
        case 'INSECURE': return KeratinErrors.Insecure;
        case 'FAILED': return KeratinErrors.Failed;
        case 'LOCKED': return KeratinErrors.Locked;
        case 'EXPIRED': return KeratinErrors.Expired;
        case 'NOT_FOUND': return KeratinErrors.NotFound;
        case 'INVALID_OR_EXPIRED': return KeratinErrors.InvalidOrExpired;
        default: return KeratinErrors.Unknown
    }
}

export class KeratinSingleton {
    private static instance: KeratinSingleton;

    restorePromise: Promise<string | undefined> | null = null;

    private constructor() {
        this.setKeratinConfig();
    }

    public static getInstance(): KeratinSingleton {
        if (!KeratinSingleton.instance) {
            KeratinSingleton.instance = new KeratinSingleton();
        }
        return KeratinSingleton.instance;
    }

    public restoreSession(): Promise<void> {
        return restoreSession()
    }

    public signup(credentials: Credentials, onSignedUp: () => void, onError: (error: KeratinErrors, errorMessage: string) => void) {
        signup(credentials).then((result) => {
            AmplitudeHelper.trackLoginRegisterUserRegistered()
            onSignedUp()
        }).catch((error: KeratinError[])=> {
            if(error) {
                BugsnagHelper.notify(new Error("Keratin signup: " + JSON.stringify(error)))
                if(error.length > 0) {
                    onError(getKeratinError(error[0].message), error[0].message)
                }else{
                    onError(KeratinErrors.Unknown, "Erreur de connexion")
                }
            }else{
                onError(KeratinErrors.Unknown, "Erreur de connexion")
                BugsnagHelper.notify(new Error("Keratin signup null error to check"))
            }
        })
    }

    public login(credentials: Credentials, onLoggedIn: () => void, onError: (error: KeratinErrors, errorMessage: string) => void) {
        login(credentials).then((result) => {
            BugsnagHelper.setUser(credentials.username)
            AmplitudeHelper.trackLoginRegisterUserLoggedIn(credentials.username)
            onLoggedIn()
        }).catch((error: KeratinError[])=> {
            if(error){
                BugsnagHelper.notify(new Error("Keratin login: "+JSON.stringify(error)))
                if(error.length > 0) {
                    onError(getKeratinError(error[0].message), error[0].message)
                }else{
                    onError(KeratinErrors.Unknown, "Erreur de connexion")
                }
            }else{
                onError(KeratinErrors.Unknown, "Erreur de connexion")
                BugsnagHelper.notify(new Error("Keratin login null error to check"))
            }
        })
    }

    public logout(onLoggedOut: () => void, onError: () => void) {
        logout().then((result) => {
            AmplitudeHelper.logOut()
            onLoggedOut()
        }).catch((error)=> {
            if(error){
                BugsnagHelper.notify(new Error("Keratin logout: "+JSON.stringify(error)))
            }
            onError()
        })
    }

    public requestPasswordReset(username: string, onSuccess: () => void, onError: () => void) {
        requestPasswordReset(username).then((result) => {
            onSuccess()
        }).catch((error)=> {
            if(error){
                BugsnagHelper.notify(new Error("Keratin request password reset: "+JSON.stringify(error)))
            }
            onError()
        })
    }

    public resetPassword(token: string, password: string, onSuccess: () => void, onError: (error: KeratinErrors) => void) {
        resetPassword({password, token}).then((result) => {
            onSuccess()
        }).catch((error)=> {
            if(error){
                BugsnagHelper.notify(new Error("Keratin reset password: "+JSON.stringify(error)))
                if(error.length > 0) {
                    onError(getKeratinError(error[0].message))
                }else{
                    onError(KeratinErrors.Unknown)
                }
            }else{
                onError(KeratinErrors.Unknown)
            }
        })
    }


    public getAccessToken(): string | undefined {
        return session()
    }

    public getNewAccessToken(): Promise<string | undefined> {
        if (this.restorePromise) {
            return this.restorePromise
        }

        async function restoreSessionAndGetToken() {
            try {
                await KeratinSingleton.getInstance().restoreSession();
            }catch(error){
                return undefined // Could not restore session, return an undefined token
            }
            return KeratinSingleton.getInstance().getAccessToken();
        }
        // Assign the Promise returned by the async function to restorePromise
        // so subsequent calls can reuse the same Promise if it's still pending or resolved.
        this.restorePromise = restoreSessionAndGetToken();

        // Make sure that the restorePromise is set back to null after the operation completes for future usage
        this.restorePromise.finally(() => {
            this.restorePromise = null;
        });

        return this.restorePromise;

    }

    private setKeratinConfig() {
        setHost(process.env.NEXT_PUBLIC_AUTH_URL ?? "");
        setCookieStore(process.env.NEXT_PUBLIC_AUTHN_COOKIE!, {sameSite: 'Lax', useExplicitExpiry: true});
    }
}