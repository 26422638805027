import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createStateSyncMiddleware } from 'redux-state-sync';
// import persistStorage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { MigrationManifest } from "redux-persist/es/types";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import thunk from 'redux-thunk';
import blogReducer from './blog/blog.reducer';
import eventReducer, { eventReducerInitialState } from "./event/event.reducer";
import themeReducer from './event/theme.reducer';
import sessionReducer from "./session/session.reducer";
import snackbarReducer from './snackbar/snackbar.reducer';
import topbarReducer from './topbar/topbar.reducer';


const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(value: any) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const expireReducer = require('redux-persist-expire');

const reducers = combineReducers({
    sessionReducer: sessionReducer,
    eventReducer: eventReducer,
    snackbarReducer: snackbarReducer,
    themeReducer: themeReducer,
    topbarReducer: topbarReducer,
    blogReducer: blogReducer,
    // orderModificationReducer: orderModificationReducer
    
});


export type RootState = ReturnType<typeof reducers>;
// export type AppDispatch = typeof store.dispatch

const migrations: MigrationManifest = {
    1: (state: any): any => {
        // migration to keep only device state
        return {

        }
    },
    2: (state: any): any => {
        // Expire all cookies. On 2023/11/13 we had a bug that led to all session cookie being invalid, instead of waiting
        // for it to be re-validated, we simply expire all cookies to force the user to re-log.
        const deleteCookie = (name: string) => {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        };
        // Delete all cookies
        const cookies = document.cookie.split(';');
        cookies.forEach(cookie => {
            const cookieName = cookie.split('=')[0].trim();
            deleteCookie(cookieName);
        });


        // migration that keep only device state
        return {

        }
    },
    3: (state: any): any => {
        // migration to keep only device state
        return {

        }
    },
}
export const persistConfig = {
    key: 'root',
    version: 3,
    storage: storage,
    blacklist: ['extras'],
    transforms: [ //TODO: Reset the cart if the user doesn't use the eventReducer for 1 day. When we'll save drafts on the user account, we won't be needing this anymore
        // Create a transformer by passing the reducer key and configuration. Values
        // shown below are the available configurations with default values
        expireReducer('eventReducer', {
            // (Required) Seconds after which store will be expired
            expireSeconds: 86400, // 1 day
            // (Optional) State to be used for resetting e.g. provide initial reducer state
            expiredState: eventReducerInitialState,
            // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey`
            // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
            autoExpire: true
        }),
        // You can add more `expireReducer` calls here for different reducers
        // that you may want to expire
    ],
    migrate: createMigrate(migrations, {debug: false})
};

const persistedReducers = persistReducer<any, any>(persistConfig, reducers);
function getStore() {
    const isServer = typeof window === "undefined";
    if (isServer) {
        return createStore(persistedReducers, {}, applyMiddleware());
    } else {

        const middlewares = [];
        middlewares.push(thunk)
         if(process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'){
             middlewares.push(logger)
         }

        const config = {
            blacklist: ["persist/PERSIST", "persist/REHYDRATE"]
        };

        middlewares.push(createStateSyncMiddleware(config))
        return createStore(persistedReducers, {}, composeWithDevTools(applyMiddleware(...middlewares)));
    }
}
export const store = getStore()
/**
 * Read this if you want to do static export: https://github.com/AOHUA/redux-state-sync/issues/18
 * redux-persist does not play well with NextJS ; check this if you want to explore another solution https://stackoverflow.com/questions/54036503/how-to-persist-redux-state-data-in-next-js
 */
export const persistor = persistStore(store);