/**
 * @fileoverview gRPC-Web generated client stub for marketing
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: marketing/marketing.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as marketing_marketing_pb from '../marketing/marketing_pb';


export class MarketingClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/marketing.Marketing/Create',
    grpcWeb.MethodType.UNARY,
    marketing_marketing_pb.BlobCreationMsg,
    marketing_marketing_pb.BlobMsg,
    (request: marketing_marketing_pb.BlobCreationMsg) => {
      return request.serializeBinary();
    },
    marketing_marketing_pb.BlobMsg.deserializeBinary
  );

  create(
    request: marketing_marketing_pb.BlobCreationMsg,
    metadata: grpcWeb.Metadata | null): Promise<marketing_marketing_pb.BlobMsg>;

  create(
    request: marketing_marketing_pb.BlobCreationMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void): grpcWeb.ClientReadableStream<marketing_marketing_pb.BlobMsg>;

  create(
    request: marketing_marketing_pb.BlobCreationMsg,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/marketing.Marketing/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/marketing.Marketing/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/marketing.Marketing/Update',
    grpcWeb.MethodType.UNARY,
    marketing_marketing_pb.BlobUpdateMsg,
    marketing_marketing_pb.BlobMsg,
    (request: marketing_marketing_pb.BlobUpdateMsg) => {
      return request.serializeBinary();
    },
    marketing_marketing_pb.BlobMsg.deserializeBinary
  );

  update(
    request: marketing_marketing_pb.BlobUpdateMsg,
    metadata: grpcWeb.Metadata | null): Promise<marketing_marketing_pb.BlobMsg>;

  update(
    request: marketing_marketing_pb.BlobUpdateMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void): grpcWeb.ClientReadableStream<marketing_marketing_pb.BlobMsg>;

  update(
    request: marketing_marketing_pb.BlobUpdateMsg,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/marketing.Marketing/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/marketing.Marketing/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/marketing.Marketing/Delete',
    grpcWeb.MethodType.UNARY,
    marketing_marketing_pb.BlobDeleteMsg,
    google_protobuf_empty_pb.Empty,
    (request: marketing_marketing_pb.BlobDeleteMsg) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  delete(
    request: marketing_marketing_pb.BlobDeleteMsg,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  delete(
    request: marketing_marketing_pb.BlobDeleteMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  delete(
    request: marketing_marketing_pb.BlobDeleteMsg,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/marketing.Marketing/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/marketing.Marketing/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/marketing.Marketing/Get',
    grpcWeb.MethodType.UNARY,
    marketing_marketing_pb.BlobGetMsg,
    marketing_marketing_pb.BlobMsg,
    (request: marketing_marketing_pb.BlobGetMsg) => {
      return request.serializeBinary();
    },
    marketing_marketing_pb.BlobMsg.deserializeBinary
  );

  get(
    request: marketing_marketing_pb.BlobGetMsg,
    metadata: grpcWeb.Metadata | null): Promise<marketing_marketing_pb.BlobMsg>;

  get(
    request: marketing_marketing_pb.BlobGetMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void): grpcWeb.ClientReadableStream<marketing_marketing_pb.BlobMsg>;

  get(
    request: marketing_marketing_pb.BlobGetMsg,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobMsg) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/marketing.Marketing/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/marketing.Marketing/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/marketing.Marketing/List',
    grpcWeb.MethodType.UNARY,
    marketing_marketing_pb.BlobListMsg,
    marketing_marketing_pb.BlobListResultMsg,
    (request: marketing_marketing_pb.BlobListMsg) => {
      return request.serializeBinary();
    },
    marketing_marketing_pb.BlobListResultMsg.deserializeBinary
  );

  list(
    request: marketing_marketing_pb.BlobListMsg,
    metadata: grpcWeb.Metadata | null): Promise<marketing_marketing_pb.BlobListResultMsg>;

  list(
    request: marketing_marketing_pb.BlobListMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobListResultMsg) => void): grpcWeb.ClientReadableStream<marketing_marketing_pb.BlobListResultMsg>;

  list(
    request: marketing_marketing_pb.BlobListMsg,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: marketing_marketing_pb.BlobListResultMsg) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/marketing.Marketing/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/marketing.Marketing/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

}

