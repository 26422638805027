// source: type/pricing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.type.PaiementType', null, global);
goog.exportSymbol('proto.type.PriceRange', null, global);
goog.exportSymbol('proto.type.PricingMsg', null, global);
goog.exportSymbol('proto.type.PricingMsg.DiscountMsg', null, global);
goog.exportSymbol('proto.type.PricingMsg.DiscountMsg.Type', null, global);
goog.exportSymbol('proto.type.PricingMsg.LabelMsg', null, global);
goog.exportSymbol('proto.type.PricingMsg.LabelMsg.Reason', null, global);
goog.exportSymbol('proto.type.PricingMsg.LabelMsg.Target', null, global);
goog.exportSymbol('proto.type.TransactionMsg', null, global);
goog.exportSymbol('proto.type.TransactionMsg.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PriceRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.PriceRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PriceRange.displayName = 'proto.type.PriceRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PricingMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.PricingMsg.repeatedFields_, null);
};
goog.inherits(proto.type.PricingMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PricingMsg.displayName = 'proto.type.PricingMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PricingMsg.DiscountMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.PricingMsg.DiscountMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PricingMsg.DiscountMsg.displayName = 'proto.type.PricingMsg.DiscountMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PricingMsg.LabelMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.PricingMsg.LabelMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PricingMsg.LabelMsg.displayName = 'proto.type.PricingMsg.LabelMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.TransactionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.TransactionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.TransactionMsg.displayName = 'proto.type.TransactionMsg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PriceRange.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PriceRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PriceRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PriceRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    packSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    coefficient: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PriceRange}
 */
proto.type.PriceRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PriceRange;
  return proto.type.PriceRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PriceRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PriceRange}
 */
proto.type.PriceRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCoefficient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PriceRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PriceRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PriceRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PriceRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPackSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCoefficient();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.type.PriceRange.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.PriceRange} returns this
 */
proto.type.PriceRange.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.type.PriceRange.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.PriceRange} returns this
 */
proto.type.PriceRange.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.type.PriceRange.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PriceRange} returns this
 */
proto.type.PriceRange.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 pack_size = 4;
 * @return {number}
 */
proto.type.PriceRange.prototype.getPackSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.PriceRange} returns this
 */
proto.type.PriceRange.prototype.setPackSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double coefficient = 5;
 * @return {number}
 */
proto.type.PriceRange.prototype.getCoefficient = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PriceRange} returns this
 */
proto.type.PriceRange.prototype.setCoefficient = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.PricingMsg.repeatedFields_ = [8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PricingMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PricingMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PricingMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    handling: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    delivery: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timeslot: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    insurance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    deposit: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    taxes: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    discount_list: jspb.Message.toObjectList(msg.getDiscountList(),
    proto.type.PricingMsg.DiscountMsg.toObject, includeInstance),
    credit: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    durationCoefficient: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.type.PricingMsg.LabelMsg.toObject, includeInstance),
    totalHt: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalTtc: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PricingMsg}
 */
proto.type.PricingMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PricingMsg;
  return proto.type.PricingMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PricingMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PricingMsg}
 */
proto.type.PricingMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProduct(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHandling(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDelivery(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimeslot(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInsurance(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeposit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxes(value);
      break;
    case 8:
      var value = new proto.type.PricingMsg.DiscountMsg;
      reader.readMessage(value,proto.type.PricingMsg.DiscountMsg.deserializeBinaryFromReader);
      msg.addDiscount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCredit(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDurationCoefficient(value);
      break;
    case 11:
      var value = new proto.type.PricingMsg.LabelMsg;
      reader.readMessage(value,proto.type.PricingMsg.LabelMsg.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalHt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTtc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PricingMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PricingMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PricingMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getHandling();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDelivery();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimeslot();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getInsurance();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTaxes();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDiscountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.type.PricingMsg.DiscountMsg.serializeBinaryToWriter
    );
  }
  f = message.getCredit();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDurationCoefficient();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.type.PricingMsg.LabelMsg.serializeBinaryToWriter
    );
  }
  f = message.getTotalHt();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalTtc();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PricingMsg.DiscountMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PricingMsg.DiscountMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PricingMsg.DiscountMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.DiscountMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PricingMsg.DiscountMsg}
 */
proto.type.PricingMsg.DiscountMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PricingMsg.DiscountMsg;
  return proto.type.PricingMsg.DiscountMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PricingMsg.DiscountMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PricingMsg.DiscountMsg}
 */
proto.type.PricingMsg.DiscountMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.PricingMsg.DiscountMsg.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PricingMsg.DiscountMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PricingMsg.DiscountMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PricingMsg.DiscountMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.DiscountMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.PricingMsg.DiscountMsg.Type = {
  PRODUCT: 0,
  DELIVERY: 1,
  TIMESLOT: 2,
  HANDLING: 3,
  INSURANCE: 4,
  TOTAL: 5
};

/**
 * optional Type type = 1;
 * @return {!proto.type.PricingMsg.DiscountMsg.Type}
 */
proto.type.PricingMsg.DiscountMsg.prototype.getType = function() {
  return /** @type {!proto.type.PricingMsg.DiscountMsg.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.PricingMsg.DiscountMsg.Type} value
 * @return {!proto.type.PricingMsg.DiscountMsg} returns this
 */
proto.type.PricingMsg.DiscountMsg.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.type.PricingMsg.DiscountMsg.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg.DiscountMsg} returns this
 */
proto.type.PricingMsg.DiscountMsg.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PricingMsg.LabelMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PricingMsg.LabelMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PricingMsg.LabelMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.LabelMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PricingMsg.LabelMsg}
 */
proto.type.PricingMsg.LabelMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PricingMsg.LabelMsg;
  return proto.type.PricingMsg.LabelMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PricingMsg.LabelMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PricingMsg.LabelMsg}
 */
proto.type.PricingMsg.LabelMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.PricingMsg.LabelMsg.Target} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {!proto.type.PricingMsg.LabelMsg.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PricingMsg.LabelMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PricingMsg.LabelMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PricingMsg.LabelMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PricingMsg.LabelMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.PricingMsg.LabelMsg.Target = {
  PRODUCT: 0,
  DELIVERY: 1,
  TIMESLOT: 2,
  HANDLING: 3,
  INSURANCE: 4,
  TOTAL: 5
};

/**
 * @enum {number}
 */
proto.type.PricingMsg.LabelMsg.Reason = {
  HOLIDAY: 0,
  SUNDAY: 1,
  NIGHT: 2,
  LASTMINUTE: 3
};

/**
 * optional Target target = 1;
 * @return {!proto.type.PricingMsg.LabelMsg.Target}
 */
proto.type.PricingMsg.LabelMsg.prototype.getTarget = function() {
  return /** @type {!proto.type.PricingMsg.LabelMsg.Target} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.PricingMsg.LabelMsg.Target} value
 * @return {!proto.type.PricingMsg.LabelMsg} returns this
 */
proto.type.PricingMsg.LabelMsg.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Reason reason = 2;
 * @return {!proto.type.PricingMsg.LabelMsg.Reason}
 */
proto.type.PricingMsg.LabelMsg.prototype.getReason = function() {
  return /** @type {!proto.type.PricingMsg.LabelMsg.Reason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.PricingMsg.LabelMsg.Reason} value
 * @return {!proto.type.PricingMsg.LabelMsg} returns this
 */
proto.type.PricingMsg.LabelMsg.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double product = 1;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getProduct = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setProduct = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double handling = 2;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getHandling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setHandling = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double delivery = 3;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getDelivery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setDelivery = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double timeslot = 4;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getTimeslot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setTimeslot = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double insurance = 5;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getInsurance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setInsurance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double deposit = 6;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double taxes = 7;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getTaxes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setTaxes = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated DiscountMsg discount = 8;
 * @return {!Array<!proto.type.PricingMsg.DiscountMsg>}
 */
proto.type.PricingMsg.prototype.getDiscountList = function() {
  return /** @type{!Array<!proto.type.PricingMsg.DiscountMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.PricingMsg.DiscountMsg, 8));
};


/**
 * @param {!Array<!proto.type.PricingMsg.DiscountMsg>} value
 * @return {!proto.type.PricingMsg} returns this
*/
proto.type.PricingMsg.prototype.setDiscountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.type.PricingMsg.DiscountMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.PricingMsg.DiscountMsg}
 */
proto.type.PricingMsg.prototype.addDiscount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.type.PricingMsg.DiscountMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.clearDiscountList = function() {
  return this.setDiscountList([]);
};


/**
 * optional double credit = 9;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getCredit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setCredit = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double duration_coefficient = 10;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getDurationCoefficient = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setDurationCoefficient = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * repeated LabelMsg labels = 11;
 * @return {!Array<!proto.type.PricingMsg.LabelMsg>}
 */
proto.type.PricingMsg.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.type.PricingMsg.LabelMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.PricingMsg.LabelMsg, 11));
};


/**
 * @param {!Array<!proto.type.PricingMsg.LabelMsg>} value
 * @return {!proto.type.PricingMsg} returns this
*/
proto.type.PricingMsg.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.type.PricingMsg.LabelMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.PricingMsg.LabelMsg}
 */
proto.type.PricingMsg.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.type.PricingMsg.LabelMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional double total_ht = 12;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getTotalHt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setTotalHt = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double total_ttc = 13;
 * @return {number}
 */
proto.type.PricingMsg.prototype.getTotalTtc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.PricingMsg} returns this
 */
proto.type.PricingMsg.prototype.setTotalTtc = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.TransactionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.type.TransactionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.TransactionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.TransactionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    paymentUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processedAt: (f = msg.getProcessedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 9, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.TransactionMsg}
 */
proto.type.TransactionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.TransactionMsg;
  return proto.type.TransactionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.TransactionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.TransactionMsg}
 */
proto.type.TransactionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentUrl(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProcessedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethod(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.TransactionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.TransactionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.TransactionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.TransactionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPaymentUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProcessedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.type.TransactionMsg.Type = {
  DEPOSIT: 0,
  FULLBALANCE: 1,
  CORRECTION: 2,
  REFUND: 3
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.type.TransactionMsg.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string payment_url = 5;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getPaymentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setPaymentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp processed_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.TransactionMsg.prototype.getProcessedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.TransactionMsg} returns this
*/
proto.type.TransactionMsg.prototype.setProcessedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.clearProcessedAt = function() {
  return this.setProcessedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionMsg.prototype.hasProcessedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.TransactionMsg.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.TransactionMsg} returns this
*/
proto.type.TransactionMsg.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionMsg.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string payment_method = 9;
 * @return {string}
 */
proto.type.TransactionMsg.prototype.getPaymentMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.TransactionMsg.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.TransactionMsg} returns this
*/
proto.type.TransactionMsg.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionMsg.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.TransactionMsg.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.TransactionMsg} returns this
*/
proto.type.TransactionMsg.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionMsg} returns this
 */
proto.type.TransactionMsg.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionMsg.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * @enum {number}
 */
proto.type.PaiementType = {
  INVOICE: 0,
  WIRE: 1,
  CARD: 2,
  CREDIT: 3
};

goog.object.extend(exports, proto.type);
