import { CatalogItemCategoryMsg } from "../../proto_generated/catalog/catalog_pb"


/// See https://www.notion.so/sedeo/How-is-ItemMsg-categories-used-75aba94bcbb240fd8ee3c05c17c76e4b for detail on the different type of categories
export class CollectionHelper {

    static COLLECTION_CATEGORY_STARTING_IDENTIFIER = "$$collection///"
    private static SORTING_CHARACTERISTIC_CATEGORY_STARTING_IDENTIFIER = "$$sorting///"


    //________________________________________________________________________________________________ Filtering methods
    static filterByCollectionSubCategories(subcategories: CatalogItemCategoryMsg.AsObject[]): CatalogItemCategoryMsg.AsObject[]{
        return subcategories.filter((subcategory) => this.isItemCategoryACollection(subcategory.name))
    }

    static filterBySortingCharacteristicSubCategories(subcategories: CatalogItemCategoryMsg[]): CatalogItemCategoryMsg[]{
        return subcategories.filter((subcategory) => this.isCategorySortingCharacteristicType(subcategory.getName()))
    }

    /**
     * Basic categories are all categories that are not the other types
     */
    static filterByBasicSubCategories(subcategories: CatalogItemCategoryMsg[]): CatalogItemCategoryMsg[]{
        return subcategories.filter((subcategory) => this.isCategoryBasicType(subcategory.getName()))
    }

    //_________________________________________________________________________________________________ Encoding methods
    // See https://www.notion.so/sedeo/How-is-ItemMsg-categories-used-75aba94bcbb240fd8ee3c05c17c76e4b for rules of
    // encoding methods below
    static encodeBasicCategory(categoryName: string): string{
        return categoryName
    }

    static encodeCollectionCategory(categoryName: string): string{
        return CollectionHelper.COLLECTION_CATEGORY_STARTING_IDENTIFIER+categoryName
    }

    static encodeSortingCharacteristicCategory(categoryName: string): string{
        return CollectionHelper.SORTING_CHARACTERISTIC_CATEGORY_STARTING_IDENTIFIER+categoryName
    }

    static getEncodedCategory(categoryType: CollectionHelper.CategoryType, categoryName: string): string{
        switch (categoryType) {
            case CollectionHelper.CategoryType.BASIC: return CollectionHelper.encodeBasicCategory(categoryName)
            case CollectionHelper.CategoryType.COLLECTION: return CollectionHelper.encodeCollectionCategory(categoryName)
            case CollectionHelper.CategoryType.SORTING_CHARACTERISTIC: return CollectionHelper.encodeSortingCharacteristicCategory(categoryName)
        }
    }


    //___________________________________________________________________________________________________ Decode methods


    /**
     * This is a Typescript way of checking that we're checking all Enum cases against a given string. If we were to add
     * another value in the CategoryType enum and we don't update this function, the IDE will show us an error.
     */
    static getCategoryType(categoryName: string): CollectionHelper.CategoryType {
        for (const categoryTypeKey in CollectionHelper.CategoryType) {
            let categoryType = CollectionHelper.CategoryType[categoryTypeKey as keyof typeof CollectionHelper.CategoryType]
            switch (categoryType) {
                case CollectionHelper.CategoryType.COLLECTION:
                    if(this.isItemCategoryACollection(categoryName)) return CollectionHelper.CategoryType.COLLECTION
                    break;
                case CollectionHelper.CategoryType.SORTING_CHARACTERISTIC:
                    if(this.isCategorySortingCharacteristicType(categoryName)) return CollectionHelper.CategoryType.SORTING_CHARACTERISTIC
                    break;
                case CollectionHelper.CategoryType.BASIC:
                    if(this.isCategoryBasicType(categoryName)) return CollectionHelper.CategoryType.BASIC
                    break;
                default:
                    // Exhaustive checking: throw an error for an unhandled enum value
                    const unhandledValue: never = categoryType;
                    throw new Error(`Unhandled enum value: ${unhandledValue}`);
            }
        }

        throw new Error(`Unhandled category type value: ${categoryName}`);
    }

    static isItemCategoryACollection(categoryName: string): boolean{
        return categoryName.startsWith(CollectionHelper.COLLECTION_CATEGORY_STARTING_IDENTIFIER)
    }

    static isCategorySortingCharacteristicType(categoryName: string): boolean{
        return categoryName.startsWith(CollectionHelper.SORTING_CHARACTERISTIC_CATEGORY_STARTING_IDENTIFIER)
    }

    /**
     * Basic categories are all categories that are not the other types
     * /!\ If you add more types of categories don't forget to update this method
     */
    static isCategoryBasicType(categoryName: string): boolean{
        return !this.isItemCategoryACollection(categoryName) && !this.isCategorySortingCharacteristicType(categoryName)
    }
}


export namespace CollectionHelper {
    export enum CategoryType {
        BASIC = "basic", COLLECTION = "collection", SORTING_CHARACTERISTIC = "sorting-characteristic"
    }
}
