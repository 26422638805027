import Bugsnag, { NotifiableError } from "@bugsnag/js";

export default class BugsnagHelper {
    static setUser(email: string){
        Bugsnag.setUser(email)
    }
    static notify(error: NotifiableError){
        Bugsnag.notify(error)
    }

    static captureMessage(message: string){
        Bugsnag.notify(message)
    }
}