import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import FR from './src/localization/fr.json';
import EN from './src/localization/en.json';

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "fr",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
        fr: {translation: FR},
        en: {translation: EN}
    },
});

export default i18n;