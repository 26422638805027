import { BlogCategory } from "../../utils/api/strapi/BlogApiHelper";

enum BlogActionType {
    SET_BLOG_CATEGORIES = "SET_BLOG_CATEGORIES",
    SET_BLOG_SELECTED_CATEGORY = "SET_BLOG_SELECTED_CATEGORY"
}

interface BlogCategoriesAction {
    type: BlogActionType.SET_BLOG_CATEGORIES
    payload:{
        categories: BlogCategory[];
    }
}

interface BlogSelectedCategoryAction {
    type: BlogActionType.SET_BLOG_SELECTED_CATEGORY
    payload:{
        selectedCategory: BlogCategory;
    }
}

type BlogActionTypes = BlogCategoriesAction | BlogSelectedCategoryAction;

interface BlogCategories {
    categories: BlogCategory[],
    selectedCategory: BlogCategory|undefined
}

export const blogCategoriesInitialState: BlogCategories = {
    categories: [],
    selectedCategory: undefined
};
 
const blogReducer = (state: BlogCategories = blogCategoriesInitialState, action: BlogActionTypes): BlogCategories => {
    switch (action.type) {
        case BlogActionType.SET_BLOG_CATEGORIES:
            return action.payload?.categories ? 
                { 
                    categories: action.payload.categories,
                    selectedCategory: undefined
                } 
                : state;
        case BlogActionType.SET_BLOG_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload?.selectedCategory
            }
        default:
            return state;
    }


    
}

export const setBlogCategories = (categories: BlogCategory[]): BlogCategoriesAction => {
    return {
        type: BlogActionType.SET_BLOG_CATEGORIES,
        payload:{ categories: categories }
    }
}

export const setSelectedBlogCategory = (category: BlogCategory): BlogSelectedCategoryAction => {
    return {
        type: BlogActionType.SET_BLOG_SELECTED_CATEGORY,
        payload:{ selectedCategory: category }
    }
}

export default blogReducer;
