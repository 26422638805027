// source: marketing/marketing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var type_pager_pb = require('../type/pager_pb.js');
goog.object.extend(proto, type_pager_pb);
goog.exportSymbol('proto.marketing.BlobCreationMsg', null, global);
goog.exportSymbol('proto.marketing.BlobDeleteMsg', null, global);
goog.exportSymbol('proto.marketing.BlobGetMsg', null, global);
goog.exportSymbol('proto.marketing.BlobListMsg', null, global);
goog.exportSymbol('proto.marketing.BlobListMsg.FilterMsg', null, global);
goog.exportSymbol('proto.marketing.BlobListResultMsg', null, global);
goog.exportSymbol('proto.marketing.BlobMsg', null, global);
goog.exportSymbol('proto.marketing.BlobMsg.BlobKind', null, global);
goog.exportSymbol('proto.marketing.BlobUpdateMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobMsg.displayName = 'proto.marketing.BlobMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobCreationMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobCreationMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobCreationMsg.displayName = 'proto.marketing.BlobCreationMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobUpdateMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobUpdateMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobUpdateMsg.displayName = 'proto.marketing.BlobUpdateMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobListMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobListMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobListMsg.displayName = 'proto.marketing.BlobListMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobListMsg.FilterMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.BlobListMsg.FilterMsg.repeatedFields_, null);
};
goog.inherits(proto.marketing.BlobListMsg.FilterMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobListMsg.FilterMsg.displayName = 'proto.marketing.BlobListMsg.FilterMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobDeleteMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobDeleteMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobDeleteMsg.displayName = 'proto.marketing.BlobDeleteMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobGetMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.BlobGetMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobGetMsg.displayName = 'proto.marketing.BlobGetMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.BlobListResultMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.BlobListResultMsg.repeatedFields_, null);
};
goog.inherits(proto.marketing.BlobListResultMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.BlobListResultMsg.displayName = 'proto.marketing.BlobListResultMsg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ownerUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobMsg}
 */
proto.marketing.BlobMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobMsg;
  return proto.marketing.BlobMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobMsg}
 */
proto.marketing.BlobMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = /** @type {!proto.marketing.BlobMsg.BlobKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOwnerUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.marketing.BlobMsg.BlobKind = {
  CATEGORY: 0,
  SUBCATEGORY: 1,
  ITEM: 2,
  COLLECTION: 3
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.marketing.BlobMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.marketing.BlobMsg.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BlobKind kind = 3;
 * @return {!proto.marketing.BlobMsg.BlobKind}
 */
proto.marketing.BlobMsg.prototype.getKind = function() {
  return /** @type {!proto.marketing.BlobMsg.BlobKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.marketing.BlobMsg.BlobKind} value
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string owner_uuid = 4;
 * @return {string}
 */
proto.marketing.BlobMsg.prototype.getOwnerUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.setOwnerUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.marketing.BlobMsg.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketing.BlobMsg.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketing.BlobMsg} returns this
*/
proto.marketing.BlobMsg.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobMsg.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketing.BlobMsg.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketing.BlobMsg} returns this
*/
proto.marketing.BlobMsg.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobMsg} returns this
 */
proto.marketing.BlobMsg.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobMsg.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 41) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobCreationMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobCreationMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobCreationMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobCreationMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.marketing.BlobMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobCreationMsg}
 */
proto.marketing.BlobCreationMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobCreationMsg;
  return proto.marketing.BlobCreationMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobCreationMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobCreationMsg}
 */
proto.marketing.BlobCreationMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketing.BlobMsg;
      reader.readMessage(value,proto.marketing.BlobMsg.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobCreationMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobCreationMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobCreationMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobCreationMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketing.BlobMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional BlobMsg blob = 1;
 * @return {?proto.marketing.BlobMsg}
 */
proto.marketing.BlobCreationMsg.prototype.getBlob = function() {
  return /** @type{?proto.marketing.BlobMsg} */ (
    jspb.Message.getWrapperField(this, proto.marketing.BlobMsg, 1));
};


/**
 * @param {?proto.marketing.BlobMsg|undefined} value
 * @return {!proto.marketing.BlobCreationMsg} returns this
*/
proto.marketing.BlobCreationMsg.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobCreationMsg} returns this
 */
proto.marketing.BlobCreationMsg.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobCreationMsg.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobUpdateMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobUpdateMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobUpdateMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobUpdateMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.marketing.BlobMsg.toObject(includeInstance, f),
    fieldMask: (f = msg.getFieldMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobUpdateMsg}
 */
proto.marketing.BlobUpdateMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobUpdateMsg;
  return proto.marketing.BlobUpdateMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobUpdateMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobUpdateMsg}
 */
proto.marketing.BlobUpdateMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketing.BlobMsg;
      reader.readMessage(value,proto.marketing.BlobMsg.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setFieldMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobUpdateMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobUpdateMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobUpdateMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobUpdateMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketing.BlobMsg.serializeBinaryToWriter
    );
  }
  f = message.getFieldMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional BlobMsg blob = 1;
 * @return {?proto.marketing.BlobMsg}
 */
proto.marketing.BlobUpdateMsg.prototype.getBlob = function() {
  return /** @type{?proto.marketing.BlobMsg} */ (
    jspb.Message.getWrapperField(this, proto.marketing.BlobMsg, 1));
};


/**
 * @param {?proto.marketing.BlobMsg|undefined} value
 * @return {!proto.marketing.BlobUpdateMsg} returns this
*/
proto.marketing.BlobUpdateMsg.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobUpdateMsg} returns this
 */
proto.marketing.BlobUpdateMsg.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobUpdateMsg.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask field_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.marketing.BlobUpdateMsg.prototype.getFieldMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.marketing.BlobUpdateMsg} returns this
*/
proto.marketing.BlobUpdateMsg.prototype.setFieldMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobUpdateMsg} returns this
 */
proto.marketing.BlobUpdateMsg.prototype.clearFieldMask = function() {
  return this.setFieldMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobUpdateMsg.prototype.hasFieldMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobListMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobListMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobListMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && type_pager_pb.PageRequestMsg.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.marketing.BlobListMsg.FilterMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobListMsg}
 */
proto.marketing.BlobListMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobListMsg;
  return proto.marketing.BlobListMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobListMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobListMsg}
 */
proto.marketing.BlobListMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new type_pager_pb.PageRequestMsg;
      reader.readMessage(value,type_pager_pb.PageRequestMsg.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new proto.marketing.BlobListMsg.FilterMsg;
      reader.readMessage(value,proto.marketing.BlobListMsg.FilterMsg.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobListMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobListMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobListMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      type_pager_pb.PageRequestMsg.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketing.BlobListMsg.FilterMsg.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.BlobListMsg.FilterMsg.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobListMsg.FilterMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobListMsg.FilterMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListMsg.FilterMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    slugsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    kindsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    ownerUuidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobListMsg.FilterMsg}
 */
proto.marketing.BlobListMsg.FilterMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobListMsg.FilterMsg;
  return proto.marketing.BlobListMsg.FilterMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobListMsg.FilterMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobListMsg.FilterMsg}
 */
proto.marketing.BlobListMsg.FilterMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUuids(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlugs(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.marketing.BlobMsg.BlobKind>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKinds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwnerUuids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobListMsg.FilterMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobListMsg.FilterMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListMsg.FilterMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getKindsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getOwnerUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string uuids = 1;
 * @return {!Array<string>}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.getUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.setUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.addUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.clearUuidsList = function() {
  return this.setUuidsList([]);
};


/**
 * repeated string slugs = 2;
 * @return {!Array<string>}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.getSlugsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.setSlugsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.addSlugs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.clearSlugsList = function() {
  return this.setSlugsList([]);
};


/**
 * repeated BlobMsg.BlobKind kinds = 3;
 * @return {!Array<!proto.marketing.BlobMsg.BlobKind>}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.getKindsList = function() {
  return /** @type {!Array<!proto.marketing.BlobMsg.BlobKind>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.marketing.BlobMsg.BlobKind>} value
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.setKindsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.marketing.BlobMsg.BlobKind} value
 * @param {number=} opt_index
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.addKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.clearKindsList = function() {
  return this.setKindsList([]);
};


/**
 * repeated string owner_uuids = 4;
 * @return {!Array<string>}
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.getOwnerUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.setOwnerUuidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.addOwnerUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.BlobListMsg.FilterMsg} returns this
 */
proto.marketing.BlobListMsg.FilterMsg.prototype.clearOwnerUuidsList = function() {
  return this.setOwnerUuidsList([]);
};


/**
 * optional type.PageRequestMsg request = 1;
 * @return {?proto.type.PageRequestMsg}
 */
proto.marketing.BlobListMsg.prototype.getRequest = function() {
  return /** @type{?proto.type.PageRequestMsg} */ (
    jspb.Message.getWrapperField(this, type_pager_pb.PageRequestMsg, 1));
};


/**
 * @param {?proto.type.PageRequestMsg|undefined} value
 * @return {!proto.marketing.BlobListMsg} returns this
*/
proto.marketing.BlobListMsg.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobListMsg} returns this
 */
proto.marketing.BlobListMsg.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobListMsg.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FilterMsg filter = 2;
 * @return {?proto.marketing.BlobListMsg.FilterMsg}
 */
proto.marketing.BlobListMsg.prototype.getFilter = function() {
  return /** @type{?proto.marketing.BlobListMsg.FilterMsg} */ (
    jspb.Message.getWrapperField(this, proto.marketing.BlobListMsg.FilterMsg, 2));
};


/**
 * @param {?proto.marketing.BlobListMsg.FilterMsg|undefined} value
 * @return {!proto.marketing.BlobListMsg} returns this
*/
proto.marketing.BlobListMsg.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobListMsg} returns this
 */
proto.marketing.BlobListMsg.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobListMsg.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobDeleteMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobDeleteMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobDeleteMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobDeleteMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobDeleteMsg}
 */
proto.marketing.BlobDeleteMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobDeleteMsg;
  return proto.marketing.BlobDeleteMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobDeleteMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobDeleteMsg}
 */
proto.marketing.BlobDeleteMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobDeleteMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobDeleteMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobDeleteMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobDeleteMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.marketing.BlobDeleteMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobDeleteMsg} returns this
 */
proto.marketing.BlobDeleteMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobGetMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobGetMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobGetMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobGetMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobGetMsg}
 */
proto.marketing.BlobGetMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobGetMsg;
  return proto.marketing.BlobGetMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobGetMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobGetMsg}
 */
proto.marketing.BlobGetMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobGetMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobGetMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobGetMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobGetMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.marketing.BlobGetMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobGetMsg} returns this
 */
proto.marketing.BlobGetMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.marketing.BlobGetMsg.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.BlobGetMsg} returns this
 */
proto.marketing.BlobGetMsg.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.BlobListResultMsg.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.BlobListResultMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.BlobListResultMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.BlobListResultMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListResultMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    blobsList: jspb.Message.toObjectList(msg.getBlobsList(),
    proto.marketing.BlobMsg.toObject, includeInstance),
    pager: (f = msg.getPager()) && type_pager_pb.PagerMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.BlobListResultMsg}
 */
proto.marketing.BlobListResultMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.BlobListResultMsg;
  return proto.marketing.BlobListResultMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.BlobListResultMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.BlobListResultMsg}
 */
proto.marketing.BlobListResultMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketing.BlobMsg;
      reader.readMessage(value,proto.marketing.BlobMsg.deserializeBinaryFromReader);
      msg.addBlobs(value);
      break;
    case 2:
      var value = new type_pager_pb.PagerMsg;
      reader.readMessage(value,type_pager_pb.PagerMsg.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.BlobListResultMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.BlobListResultMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.BlobListResultMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.BlobListResultMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marketing.BlobMsg.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      type_pager_pb.PagerMsg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlobMsg blobs = 1;
 * @return {!Array<!proto.marketing.BlobMsg>}
 */
proto.marketing.BlobListResultMsg.prototype.getBlobsList = function() {
  return /** @type{!Array<!proto.marketing.BlobMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketing.BlobMsg, 1));
};


/**
 * @param {!Array<!proto.marketing.BlobMsg>} value
 * @return {!proto.marketing.BlobListResultMsg} returns this
*/
proto.marketing.BlobListResultMsg.prototype.setBlobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marketing.BlobMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.BlobMsg}
 */
proto.marketing.BlobListResultMsg.prototype.addBlobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marketing.BlobMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.BlobListResultMsg} returns this
 */
proto.marketing.BlobListResultMsg.prototype.clearBlobsList = function() {
  return this.setBlobsList([]);
};


/**
 * optional type.PagerMsg pager = 2;
 * @return {?proto.type.PagerMsg}
 */
proto.marketing.BlobListResultMsg.prototype.getPager = function() {
  return /** @type{?proto.type.PagerMsg} */ (
    jspb.Message.getWrapperField(this, type_pager_pb.PagerMsg, 2));
};


/**
 * @param {?proto.type.PagerMsg|undefined} value
 * @return {!proto.marketing.BlobListResultMsg} returns this
*/
proto.marketing.BlobListResultMsg.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.BlobListResultMsg} returns this
 */
proto.marketing.BlobListResultMsg.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.BlobListResultMsg.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.marketing);
