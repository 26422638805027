import { CartType, OrderInformationInfo, OrderShippingInfo } from '../../typing/types';
import { MarketCategoryWithItemsAsObject } from '../../ui/market/MarketHelper';
import { EventDataType } from "./EventDataType";
import { EventActionContract } from './event.actions-contract';
import { EventActionType } from './event.types';
import {OrderingStep} from "../../utils/AmplitudeHelper";

export interface EventState {
    currentEvent: EventDataType|null;
    cart: CartType;
    shippingInfo: OrderShippingInfo|null;
    shippingDetails: OrderInformationInfo|null;
    marketCategoriesWithItems: MarketCategoryWithItemsAsObject[];
}

export const eventReducerInitialState: EventState = {
    currentEvent: null,
    cart: {items: []},
    shippingInfo: null,
    shippingDetails: null,
    marketCategoriesWithItems: [],
}

const eventReducer = (state: EventState = eventReducerInitialState, action: EventActionContract): EventState => {
    switch (action.type) {
        case EventActionType.SET_NEW_EVENT: {
            const {cart, googleSelectedPlace, SPToken, defaultMarketRedirectionUrl} = action.payload;

            let eventData: EventDataType = {cart, googleSelectedPlace, maxOrderingStep: OrderingStep.NONE, SPToken, defaultMarketRedirectionUrl}
            // State
            return {
                ...state,
                currentEvent: eventData,
                shippingInfo: null,
                shippingDetails: null,
                cart: { items: [] },
                marketCategoriesWithItems: [],
            }
        }
        case EventActionType.UPDATE_EVENT_INFO: {
            const {eventData} = action.payload;

            // State
            return {
                ...state,
                currentEvent: eventData,
                shippingInfo: state.shippingInfo,
                shippingDetails: state.shippingDetails,
                marketCategoriesWithItems: [],
            }
        }
        case EventActionType.UPDATE_MAX_ORDERING_STEP: {
            const {step} = action.payload;

            let eventData = state.currentEvent
            if(eventData && eventData.maxOrderingStep < step){
                eventData.maxOrderingStep = step
            }
            // State
            return {
                ...state,
                currentEvent: eventData,
                shippingInfo: state.shippingInfo,
                shippingDetails: state.shippingDetails,
                marketCategoriesWithItems: [],
            }
        }
        case EventActionType.RESUME_EVENT: {
            const {eventState} = action.payload;

            // State
            return eventState
        }
        case EventActionType.RESET_ALL: {
            return eventReducerInitialState
        }
        case EventActionType.UPDATE_CART: {
            const {cart} = action.payload;
            // if we are UPDATING the cart, it means that the current event can't be null
            // State
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent!,
                    cart: cart
                }
            }
        }
        case EventActionType.SET_ORDER_INFORMATION_INFO: {
            const {orderInformationInfo} = action.payload;
            return {
                ...state,
                shippingDetails: orderInformationInfo
            }
        }
        default:
            return state;
    }
};


export default eventReducer;
