// source: catalog/catalog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_pager_pb = require('../type/pager_pb.js');
goog.object.extend(proto, type_pager_pb);
var type_pricing_pb = require('../type/pricing_pb.js');
goog.object.extend(proto, type_pricing_pb);
var type_media_pb = require('../type/media_pb.js');
goog.object.extend(proto, type_media_pb);
goog.exportSymbol('proto.catalog.CatalogGetMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogGetResultMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogItemCategoryMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogItemComponents', null, global);
goog.exportSymbol('proto.catalog.CatalogItemMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogListMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogListMsg.FilterMsg', null, global);
goog.exportSymbol('proto.catalog.CatalogListResultMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogItemCategoryMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.catalog.CatalogItemCategoryMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogItemCategoryMsg.displayName = 'proto.catalog.CatalogItemCategoryMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogItemComponents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.catalog.CatalogItemComponents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogItemComponents.displayName = 'proto.catalog.CatalogItemComponents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogItemMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.catalog.CatalogItemMsg.repeatedFields_, null);
};
goog.inherits(proto.catalog.CatalogItemMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogItemMsg.displayName = 'proto.catalog.CatalogItemMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogGetMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.catalog.CatalogGetMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogGetMsg.displayName = 'proto.catalog.CatalogGetMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogGetResultMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.catalog.CatalogGetResultMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogGetResultMsg.displayName = 'proto.catalog.CatalogGetResultMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogListMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.catalog.CatalogListMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogListMsg.displayName = 'proto.catalog.CatalogListMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogListMsg.FilterMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.catalog.CatalogListMsg.FilterMsg.repeatedFields_, null);
};
goog.inherits(proto.catalog.CatalogListMsg.FilterMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogListMsg.FilterMsg.displayName = 'proto.catalog.CatalogListMsg.FilterMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.catalog.CatalogListResultMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.catalog.CatalogListResultMsg.repeatedFields_, null);
};
goog.inherits(proto.catalog.CatalogListResultMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.catalog.CatalogListResultMsg.displayName = 'proto.catalog.CatalogListResultMsg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogItemCategoryMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogItemCategoryMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemCategoryMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogItemCategoryMsg}
 */
proto.catalog.CatalogItemCategoryMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogItemCategoryMsg;
  return proto.catalog.CatalogItemCategoryMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogItemCategoryMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogItemCategoryMsg}
 */
proto.catalog.CatalogItemCategoryMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogItemCategoryMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogItemCategoryMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemCategoryMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
 */
proto.catalog.CatalogItemCategoryMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
 */
proto.catalog.CatalogItemCategoryMsg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
 */
proto.catalog.CatalogItemCategoryMsg.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
*/
proto.catalog.CatalogItemCategoryMsg.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
 */
proto.catalog.CatalogItemCategoryMsg.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
*/
proto.catalog.CatalogItemCategoryMsg.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogItemCategoryMsg} returns this
 */
proto.catalog.CatalogItemCategoryMsg.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogItemCategoryMsg.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 41) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogItemComponents.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogItemComponents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogItemComponents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemComponents.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    component: (f = msg.getComponent()) && proto.catalog.CatalogItemMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogItemComponents}
 */
proto.catalog.CatalogItemComponents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogItemComponents;
  return proto.catalog.CatalogItemComponents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogItemComponents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogItemComponents}
 */
proto.catalog.CatalogItemComponents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 2:
      var value = new proto.catalog.CatalogItemMsg;
      reader.readMessage(value,proto.catalog.CatalogItemMsg.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogItemComponents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogItemComponents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogItemComponents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemComponents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.catalog.CatalogItemMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 quantity = 1;
 * @return {number}
 */
proto.catalog.CatalogItemComponents.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.catalog.CatalogItemComponents} returns this
 */
proto.catalog.CatalogItemComponents.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CatalogItemMsg component = 2;
 * @return {?proto.catalog.CatalogItemMsg}
 */
proto.catalog.CatalogItemComponents.prototype.getComponent = function() {
  return /** @type{?proto.catalog.CatalogItemMsg} */ (
    jspb.Message.getWrapperField(this, proto.catalog.CatalogItemMsg, 2));
};


/**
 * @param {?proto.catalog.CatalogItemMsg|undefined} value
 * @return {!proto.catalog.CatalogItemComponents} returns this
*/
proto.catalog.CatalogItemComponents.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogItemComponents} returns this
 */
proto.catalog.CatalogItemComponents.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogItemComponents.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.catalog.CatalogItemMsg.repeatedFields_ = [3,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogItemMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogItemMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogItemMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.catalog.CatalogItemCategoryMsg.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.catalog.CatalogItemComponents.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    type_media_pb.ImageMsg.toObject, includeInstance),
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    type_pricing_pb.PriceRange.toObject, includeInstance),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogItemMsg}
 */
proto.catalog.CatalogItemMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogItemMsg;
  return proto.catalog.CatalogItemMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogItemMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogItemMsg}
 */
proto.catalog.CatalogItemMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.catalog.CatalogItemCategoryMsg;
      reader.readMessage(value,proto.catalog.CatalogItemCategoryMsg.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.catalog.CatalogItemComponents;
      reader.readMessage(value,proto.catalog.CatalogItemComponents.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    case 6:
      var value = new type_media_pb.ImageMsg;
      reader.readMessage(value,type_media_pb.ImageMsg.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 7:
      var value = new type_pricing_pb.PriceRange;
      reader.readMessage(value,type_pricing_pb.PriceRange.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogItemMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogItemMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogItemMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogItemMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.catalog.CatalogItemCategoryMsg.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.catalog.CatalogItemComponents.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      type_media_pb.ImageMsg.serializeBinaryToWriter
    );
  }
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      type_pricing_pb.PriceRange.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.catalog.CatalogItemMsg.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.catalog.CatalogItemMsg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CatalogItemCategoryMsg categories = 3;
 * @return {!Array<!proto.catalog.CatalogItemCategoryMsg>}
 */
proto.catalog.CatalogItemMsg.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.catalog.CatalogItemCategoryMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.catalog.CatalogItemCategoryMsg, 3));
};


/**
 * @param {!Array<!proto.catalog.CatalogItemCategoryMsg>} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.catalog.CatalogItemCategoryMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.catalog.CatalogItemCategoryMsg}
 */
proto.catalog.CatalogItemMsg.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.catalog.CatalogItemCategoryMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.catalog.CatalogItemMsg.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CatalogItemComponents components = 5;
 * @return {!Array<!proto.catalog.CatalogItemComponents>}
 */
proto.catalog.CatalogItemMsg.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.catalog.CatalogItemComponents>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.catalog.CatalogItemComponents, 5));
};


/**
 * @param {!Array<!proto.catalog.CatalogItemComponents>} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.catalog.CatalogItemComponents=} opt_value
 * @param {number=} opt_index
 * @return {!proto.catalog.CatalogItemComponents}
 */
proto.catalog.CatalogItemMsg.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.catalog.CatalogItemComponents, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearComponentsList = function() {
  return this.setComponentsList([]);
};


/**
 * repeated type.ImageMsg images = 6;
 * @return {!Array<!proto.type.ImageMsg>}
 */
proto.catalog.CatalogItemMsg.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.type.ImageMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_media_pb.ImageMsg, 6));
};


/**
 * @param {!Array<!proto.type.ImageMsg>} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.ImageMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.ImageMsg}
 */
proto.catalog.CatalogItemMsg.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.ImageMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated type.PriceRange prices = 7;
 * @return {!Array<!proto.type.PriceRange>}
 */
proto.catalog.CatalogItemMsg.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.type.PriceRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_pricing_pb.PriceRange, 7));
};


/**
 * @param {!Array<!proto.type.PriceRange>} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.type.PriceRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.PriceRange}
 */
proto.catalog.CatalogItemMsg.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.type.PriceRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional google.protobuf.Timestamp created = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.catalog.CatalogItemMsg.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogItemMsg.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.catalog.CatalogItemMsg.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.catalog.CatalogItemMsg} returns this
*/
proto.catalog.CatalogItemMsg.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogItemMsg} returns this
 */
proto.catalog.CatalogItemMsg.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogItemMsg.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 41) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogGetMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogGetMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogGetMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogGetMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spatialToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogGetMsg}
 */
proto.catalog.CatalogGetMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogGetMsg;
  return proto.catalog.CatalogGetMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogGetMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogGetMsg}
 */
proto.catalog.CatalogGetMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpatialToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogGetMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogGetMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogGetMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogGetMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpatialToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string item_uuid = 1;
 * @return {string}
 */
proto.catalog.CatalogGetMsg.prototype.getItemUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogGetMsg} returns this
 */
proto.catalog.CatalogGetMsg.prototype.setItemUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string spatial_token = 2;
 * @return {string}
 */
proto.catalog.CatalogGetMsg.prototype.getSpatialToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogGetMsg} returns this
 */
proto.catalog.CatalogGetMsg.prototype.setSpatialToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogGetResultMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogGetResultMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogGetResultMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogGetResultMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.catalog.CatalogItemMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogGetResultMsg}
 */
proto.catalog.CatalogGetResultMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogGetResultMsg;
  return proto.catalog.CatalogGetResultMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogGetResultMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogGetResultMsg}
 */
proto.catalog.CatalogGetResultMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.catalog.CatalogItemMsg;
      reader.readMessage(value,proto.catalog.CatalogItemMsg.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogGetResultMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogGetResultMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogGetResultMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogGetResultMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.catalog.CatalogItemMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional CatalogItemMsg item = 1;
 * @return {?proto.catalog.CatalogItemMsg}
 */
proto.catalog.CatalogGetResultMsg.prototype.getItem = function() {
  return /** @type{?proto.catalog.CatalogItemMsg} */ (
    jspb.Message.getWrapperField(this, proto.catalog.CatalogItemMsg, 1));
};


/**
 * @param {?proto.catalog.CatalogItemMsg|undefined} value
 * @return {!proto.catalog.CatalogGetResultMsg} returns this
*/
proto.catalog.CatalogGetResultMsg.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogGetResultMsg} returns this
 */
proto.catalog.CatalogGetResultMsg.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogGetResultMsg.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogListMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogListMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogListMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && type_pager_pb.PageRequestMsg.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.catalog.CatalogListMsg.FilterMsg.toObject(includeInstance, f),
    spatialToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogListMsg}
 */
proto.catalog.CatalogListMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogListMsg;
  return proto.catalog.CatalogListMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogListMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogListMsg}
 */
proto.catalog.CatalogListMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new type_pager_pb.PageRequestMsg;
      reader.readMessage(value,type_pager_pb.PageRequestMsg.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new proto.catalog.CatalogListMsg.FilterMsg;
      reader.readMessage(value,proto.catalog.CatalogListMsg.FilterMsg.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpatialToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogListMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogListMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogListMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      type_pager_pb.PageRequestMsg.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.catalog.CatalogListMsg.FilterMsg.serializeBinaryToWriter
    );
  }
  f = message.getSpatialToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.catalog.CatalogListMsg.FilterMsg.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogListMsg.FilterMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogListMsg.FilterMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListMsg.FilterMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesUuidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogListMsg.FilterMsg}
 */
proto.catalog.CatalogListMsg.FilterMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogListMsg.FilterMsg;
  return proto.catalog.CatalogListMsg.FilterMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogListMsg.FilterMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogListMsg.FilterMsg}
 */
proto.catalog.CatalogListMsg.FilterMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoriesUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogListMsg.FilterMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogListMsg.FilterMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListMsg.FilterMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesUuidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string categories_uuid = 2;
 * @return {!Array<string>}
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.getCategoriesUuidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.catalog.CatalogListMsg.FilterMsg} returns this
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.setCategoriesUuidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.catalog.CatalogListMsg.FilterMsg} returns this
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.addCategoriesUuid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogListMsg.FilterMsg} returns this
 */
proto.catalog.CatalogListMsg.FilterMsg.prototype.clearCategoriesUuidList = function() {
  return this.setCategoriesUuidList([]);
};


/**
 * optional type.PageRequestMsg request = 1;
 * @return {?proto.type.PageRequestMsg}
 */
proto.catalog.CatalogListMsg.prototype.getRequest = function() {
  return /** @type{?proto.type.PageRequestMsg} */ (
    jspb.Message.getWrapperField(this, type_pager_pb.PageRequestMsg, 1));
};


/**
 * @param {?proto.type.PageRequestMsg|undefined} value
 * @return {!proto.catalog.CatalogListMsg} returns this
*/
proto.catalog.CatalogListMsg.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogListMsg} returns this
 */
proto.catalog.CatalogListMsg.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogListMsg.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FilterMsg filter = 2;
 * @return {?proto.catalog.CatalogListMsg.FilterMsg}
 */
proto.catalog.CatalogListMsg.prototype.getFilter = function() {
  return /** @type{?proto.catalog.CatalogListMsg.FilterMsg} */ (
    jspb.Message.getWrapperField(this, proto.catalog.CatalogListMsg.FilterMsg, 2));
};


/**
 * @param {?proto.catalog.CatalogListMsg.FilterMsg|undefined} value
 * @return {!proto.catalog.CatalogListMsg} returns this
*/
proto.catalog.CatalogListMsg.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogListMsg} returns this
 */
proto.catalog.CatalogListMsg.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogListMsg.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string spatial_token = 3;
 * @return {string}
 */
proto.catalog.CatalogListMsg.prototype.getSpatialToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.catalog.CatalogListMsg} returns this
 */
proto.catalog.CatalogListMsg.prototype.setSpatialToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.catalog.CatalogListResultMsg.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.catalog.CatalogListResultMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.catalog.CatalogListResultMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.catalog.CatalogListResultMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListResultMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.catalog.CatalogItemMsg.toObject, includeInstance),
    pager: (f = msg.getPager()) && type_pager_pb.PagerMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.catalog.CatalogListResultMsg}
 */
proto.catalog.CatalogListResultMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.catalog.CatalogListResultMsg;
  return proto.catalog.CatalogListResultMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.catalog.CatalogListResultMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.catalog.CatalogListResultMsg}
 */
proto.catalog.CatalogListResultMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.catalog.CatalogItemMsg;
      reader.readMessage(value,proto.catalog.CatalogItemMsg.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new type_pager_pb.PagerMsg;
      reader.readMessage(value,type_pager_pb.PagerMsg.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.catalog.CatalogListResultMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.catalog.CatalogListResultMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.catalog.CatalogListResultMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.catalog.CatalogListResultMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.catalog.CatalogItemMsg.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      type_pager_pb.PagerMsg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CatalogItemMsg items = 1;
 * @return {!Array<!proto.catalog.CatalogItemMsg>}
 */
proto.catalog.CatalogListResultMsg.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.catalog.CatalogItemMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.catalog.CatalogItemMsg, 1));
};


/**
 * @param {!Array<!proto.catalog.CatalogItemMsg>} value
 * @return {!proto.catalog.CatalogListResultMsg} returns this
*/
proto.catalog.CatalogListResultMsg.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.catalog.CatalogItemMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.catalog.CatalogItemMsg}
 */
proto.catalog.CatalogListResultMsg.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.catalog.CatalogItemMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.catalog.CatalogListResultMsg} returns this
 */
proto.catalog.CatalogListResultMsg.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional type.PagerMsg pager = 2;
 * @return {?proto.type.PagerMsg}
 */
proto.catalog.CatalogListResultMsg.prototype.getPager = function() {
  return /** @type{?proto.type.PagerMsg} */ (
    jspb.Message.getWrapperField(this, type_pager_pb.PagerMsg, 2));
};


/**
 * @param {?proto.type.PagerMsg|undefined} value
 * @return {!proto.catalog.CatalogListResultMsg} returns this
*/
proto.catalog.CatalogListResultMsg.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.catalog.CatalogListResultMsg} returns this
 */
proto.catalog.CatalogListResultMsg.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.catalog.CatalogListResultMsg.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.catalog);
