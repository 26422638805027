import { SnackbarType } from '../../ui/common/CustomSnackbar';
import { SnackbarAction, SnackbarActionType } from './snackbar.actions';

export enum SnackbarTypeEnum {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING"
}

interface SnackbarState {
    isOpen: boolean,
    snackbarType: SnackbarType,
    message: string
}

export const snackbarReducerInitialState: SnackbarState = {
    isOpen: false,
    snackbarType: SnackbarTypeEnum.SUCCESS,
    message: ''
};

const snackbarReducer = (state: SnackbarState = snackbarReducerInitialState, action: SnackbarAction) => {
    switch (action.type) {
        case SnackbarActionType.OPEN: {
            return {
                ...state,
                isOpen: true,
                snackbarType: action.payload.snackbarType,
                message: action.payload.message,
                time: action.payload.time,
            }
        }
        case SnackbarActionType.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }
        default:
            return state;
    }
};

export default snackbarReducer;
