import { ReactSVG } from "react-svg";

export default function Maintenance () {
    return (
        <div className="maintenance—page">
            
            <ReactSVG className='sedeo-maintenance-logo' src="/assets/sedeo_maintenance_logo2.svg" title="sedeo-logo"/>

            <h1 className="title">Maintenance en cours !</h1>
            <p>
                On prend un petit peu de temps pour vous en faire gagner plein avec nos nouvelles fonctionnalités..
            </p>
            <p>
                Promis on fait au plus vite !
            </p>
        </div>
    )
}
