import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const PageTransitionLoading = () => {
    const [progress, setProgress] = useState(-1);
    const [isTransitioningPage, setIsTransitioningPage] = useState(true);
    const [ariaDescription, setAriaDescription] = useState('')
    const router = useRouter();
    
    useEffect(() => {
        let timer: NodeJS.Timer;

        const handleRouteChange = (url: any) => {
            setAriaDescription(url);
            setProgress(0)
            setIsTransitioningPage(true)
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        clearInterval(timer);
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);
            return;
        };
        
        const handleRouteComplete = (url: any) => {
            setAriaDescription(url);
            setProgress(100);
            setIsTransitioningPage(false)
            setTimeout(() => {
                setProgress(0)
            }, 1000);
            return;
        }; 
        
        router.events.on('routeChangeStart', handleRouteChange)
        router.events.on('routeChangeComplete', handleRouteComplete)
  
      return () => {
        clearInterval(timer);
        router.events.off('routeChangeStart', handleRouteChange)
      };
    }, []);

    return <LinearProgress 
        variant="determinate" 
        value={progress} 
        className={`page-transition-loading ${(progress > -1 && isTransitioningPage) ? 'show' : ''}`}
        aria-label={"Page loader"}
        aria-describedby={ariaDescription}
        aria-busy={isTransitioningPage}
        />
    
}