// or ESM/TypeScript import
import Ajv from "ajv"

const marketCategorySchema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string",
            "minLength": 1
        },
        "title": {
            "type": "string",
            "minLength": 1
        },
        "headTags": {
            "type": "array",
            "items": {}
        },
        "description": {
            "type": "string",
            "minLength": 1
        },
        "shortDescription": {
            "type": "string",
            "minLength": 1
        },
        "isPresentable": {
            "type": "boolean"
        },
        "detailPageTitle": {
            "type": "string",
            "minLength": 1
        },
        "detailPageDescription": {
            "type": "string",
            "minLength": 1
        },
        "marketSubCategoriesSlug": {
            "type": "array",
            "items": [
                {
                    "type": "string"
                }
            ],
            "minItems": 1,
        },
        "order": {
            "type": "number"
        },
    },
    "required": [
        "name",
        "title",
        "headTags",
        "description",
        "shortDescription",
        "isPresentable",
        "detailPageTitle",
        "detailPageDescription",
        "marketSubCategoriesSlug"
    ]
}

const marketSubCategorySchema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string",
            "minLength": 1
        },
        "title": {
            "type": "string",
            "minLength": 1
        },
        "headTags": {
            "type": "array",
            "items": {}
        },
        "itemOrders": {
            "type": "array",
            "items": {}
        },
        "description": {
            "type": "string",
            "minLength": 1
        },
        "shortDescription": {
            "type": "string",
            "minLength": 1
        },
        "subCategoryH1": {
            "type": "string",
            "minLength": 1
        },
        "backEndCategoryUuid": {
            "type": "string",
            "minLength": 1
        }
    },
    "required": [
        "name",
        "title",
        "headTags",
        "itemOrders",
        "description",
        "shortDescription",
        "subCategoryH1",
        "backEndCategoryUuid"
    ]
}

const marketItemSchema = {
    "type": "object",
    "properties": {
        "headTags": {
            "type": "array",
            "items": {}
        },
        "marketCategorySlug": {
            "type": "string"
        },
        "marketSubCategorySlug": {
            "type": "string"
        }
    },
    "required": [
        "headTags",
        "marketCategorySlug",
        "marketSubCategorySlug"
    ]
}


const marketCollectionSchema = {
    "type": "object",
    "properties": {
        "marketCategorySlug": {
            "type": "string",
            "minLength": 1
        },
        "marketSubCategorySlug": {
            "type": "string",
            "minLength": 1
        },
        "name": {
            "type": "string",
            "minLength": 1
        },
        "description": {
            "type": "string",
            "minLength": 1
        },
        "itemCategoryUuid": {
            "type": "string",
            "minLength": 1
        },
        "images": {
            "type": "array",
            "items": {}
        },
        "itemOrders": {
            "type": "array",
            "items": {}
        },
        "headTags": {
            "type": "array",
            "items": {}
        },
    },
    "required": [
        "marketCategorySlug",
        "marketSubCategorySlug",
        "name",
        "description",
        "itemCategoryUuid",
        "images",
        "itemOrders",
        "headTags",
    ]
}

export enum JsonSchemaValidatorType {
    MARKET_CATEGORY, MARKET_SUB_CATEGORY, MARKET_ITEM, MARKET_COLLECTION
}

/**
 * This class helps you validate json blob types according to pre-defined json schema. It returns undefined if no errors were found.
 * These schemas are given by the Admin. The goal is to have the Marketplace be able to check that the incoming data is valid
 * for its usage but also allow the Admin to be ahead and add parameters to the json in advance without throwing errors.
 *
 * Let's say we have a V2 of the Marketplace in production that has this json validation for the MarketCategory:
 * "required": [
 *         "name",
 *         "title",
 *         "headTags",
 *         "detailPageTitle",
 *         "marketSubCategoriesSlug"
 *     ]
 *
 * The V3 of the Marketplace will have a "foo" field added. In order to have the data ready for that version, the admin will
 * modify the json blob. The way the schema is done, we won't be throwing errors in the V2 when checking the json if the "foo" field is present.
 *
 * When pushing the V3 into production you will have to add the "foo" into the json-schema. This is the job of the Admin maintainers
 * to tell the Marketplace maintainers to update these json-schemas.
 */
export default class JsonSchemaValidator {
    static getErrors(type: JsonSchemaValidatorType, json: any){

        let schema: any
        switch (type) {
            case JsonSchemaValidatorType.MARKET_CATEGORY:
                schema = marketCategorySchema
                break;
            case JsonSchemaValidatorType.MARKET_SUB_CATEGORY:
                schema = marketSubCategorySchema
                break;
            case JsonSchemaValidatorType.MARKET_ITEM:
                schema = marketItemSchema
                break;
            case JsonSchemaValidatorType.MARKET_COLLECTION:
                schema = marketCollectionSchema
                break;
            //We make this exhaustive check to force the code to crash wherever it lands on this default case,
            //as it should never arrive to this default case and every case should be handled.
            default: const _exhaustiveCheck: never = type;
        }

        const ajv = new Ajv({ strictTuples: false, allErrors: true, verbose: true})
        const valid = ajv.validate(schema, json)

        if(valid){
            return undefined
        }else{
            if(ajv.errors){
                return JSON.stringify(ajv.errors?.map((error) =>  {
                    return {params: error.params, message: error.message }
                }))
            }else{
                return "JsonSchemaValidator#getErrors had error but couldn't read it. Happened for: "+JsonSchemaValidatorType[type]
            }
        }

    }
}