import { PageRequestMsg } from "../../../../proto_generated/type/pager_pb";
import {MarketItem, MarketItemContent,} from "../../../typing/types";
import {MarketingClient} from "../../../../proto_generated/marketing/MarketingServiceClientPb";
import {BlobGetMsg, BlobListMsg, BlobMsg} from "../../../../proto_generated/marketing/marketing_pb";
import FilterMsg = BlobListMsg.FilterMsg;
import BlobKind = BlobMsg.BlobKind;
import JsonSchemaValidator, {JsonSchemaValidatorType} from "../../cms/JsonSchemaValidator";

export default class MarketItemAPIHelper {

    //#region private stuff of the class
    static #ENV_URL = process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!;

    static #prepareMarketingClient = () => {
        return new MarketingClient(this.#ENV_URL);
    }
    //#endregion

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketItemsByOwners = async (itemUuids: string[], withDecodingErrorCheck: boolean = true): Promise<MarketItem[]> => {
        return new Promise<MarketItem[]>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(999999)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.ITEM])
            filterMsg.setOwnerUuidsList(itemUuids)
            blobListMsg.setFilter(filterMsg)

            this.#prepareMarketingClient().list(blobListMsg, {}, (err, res) => {
                if(res){
                    let marketItems: MarketItem[] = []
                    let errors: string[] = []

                    res.getBlobsList().forEach((blob) => {
                        let decodedContent = JSON.parse(blob.getContent())

                        // Check that JSON has the correct MarketItem format
                        const decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_ITEM, decodedContent) : undefined;
                        if(decodingErrors){
                            errors.push(decodingErrors)
                        }else {
                            marketItems.push({
                                uuid: blob.getUuid(),
                                catalogItemUuid: blob.getOwnerUuid(),
                                slug: blob.getSlug(),
                                content: {
                                    marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                    marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                    headTags: decodedContent.headTags ?? ''
                                }
                            })
                        }
                    })
                    // If any decoding went wrong, reject
                    if(errors.length > 0){
                        reject(errors.join(', '))
                    }else {
                        resolve(marketItems)
                    }
                }else{
                    console.error(err)
                    reject(err)
                }
            })
        })
    }



    /**
     * /!\ An Item can only have 1 corresponding MarketItem. Read this for more information /!\
     *
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketItemByItemUuid = async (itemUuid: string, withDecodingErrorCheck: boolean = true): Promise<MarketItem> => {
        return new Promise<MarketItem>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(3)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.ITEM])
            filterMsg.setOwnerUuidsList([itemUuid])
            blobListMsg.setFilter(filterMsg)

            this.#prepareMarketingClient().list(blobListMsg, {}, (err, res) => {
                if(res){
                    if(res.getBlobsList().length === 0){
                        reject("getMarketItemByItemUuid no market item found:"+itemUuid)
                    }else if(res.getBlobsList().length > 1){
                        reject("getMarketItemByItemUuid too many items found:"+itemUuid)
                    }else {
                        let blob = res.getBlobsList()[0]
                        let decodedContent: MarketItemContent = JSON.parse(blob.getContent());

                        // Check that JSON has the correct MarketItem format
                        let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_ITEM, decodedContent) : undefined;
                        if(decodingErrors){
                            reject(decodingErrors)
                        }else{
                            let marketItem: MarketItem = {
                                uuid: blob.getUuid(),
                                catalogItemUuid: itemUuid,
                                slug: blob.getSlug(),
                                content: {
                                    marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                    marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                    headTags: decodedContent.headTags ?? ''
                                }
                            }

                            resolve(marketItem)
                        }
                    }
                }else{
                    console.error(err)
                    reject("getMarketItemByItemUuid error:"+err.message+" | for: "+itemUuid)
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketItemBySlug = async (slug: string, withDecodingErrorCheck: boolean = true): Promise<MarketItem> => {
        return new Promise<MarketItem>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(slug)
            this.#prepareMarketingClient().get(blobGetMsg, {}, (err, res) => {
                if(res){
                    let decodedContent: MarketItemContent = JSON.parse(res.getContent());
                    // Check that JSON has the correct MarketItem format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_ITEM, decodedContent) : undefined;
                    if(decodingErrors){
                        reject(decodingErrors)
                    }else {
                        let marketItem: MarketItem = {
                            uuid: res.getUuid(),
                            catalogItemUuid: res.getOwnerUuid(),
                            slug: res.getSlug(),
                            content: {
                                marketCategorySlug: decodedContent.marketCategorySlug ?? '',
                                marketSubCategorySlug: decodedContent.marketSubCategorySlug ?? '',
                                headTags: decodedContent.headTags ?? ''
                            }
                        }

                        resolve(marketItem)
                    }
                }else{
                    console.error(err)
                    if(err.message === "marketing blob not found") {
                        reject("getMarketItemBySlug error: marketing blob not found")
                    }else{
                        reject("getMarketItemBySlug error:"+err.message)
                    }
                }
            })
        })

    }

}
