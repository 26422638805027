import {MarketCollection, MarketItem, ProductItemType} from "../typing/types";
import 'i18n';
import { t } from "i18next";

export const BASE_TOPBAR_HEIGHT = 80;

export const SEDEO_PARTNER_EMAIL = "partenaire@sedeo.fr"

export const UNAUTHORIZED_FLAG = 'UNAUTHORIZED';

export const DEFAULT_API_DEBOUNCE_TIME = 500

export const ROUTES_BASE = {
    ROOT: '/',
    ORDERS: '/order',
    ACCOUNT: '/account',
    JOBS: '/jobs',
    CATALOG: '/catalog',
    CATALOGUE: '/catalogue',
    BLOG: '/blog',
    SEARCH: '/search',
    MAINTENANCE: '/maintenance'
}

export const ROUTES = {
    ORDERS: {
        CART: ROUTES_BASE.ORDERS+'/cart',
        SHIPPING: ROUTES_BASE.ORDERS+'/shipping',
        INFORMATION: ROUTES_BASE.ORDERS+'/information',
        VALIDATION: ROUTES_BASE.ORDERS+'/validation',
        CONFIRMATION: ROUTES_BASE.ORDERS+'/confirmation',
        RECAP: ROUTES_BASE.ORDERS+'/recap',
        PAYMENT: ROUTES_BASE.ORDERS+'/payment'
    },
    ACCOUNT: {
        LOGIN: ROUTES_BASE.ACCOUNT+'/login',
        SETTINGS: ROUTES_BASE.ACCOUNT+'/settings',
        ASSISTANT: ROUTES_BASE.ACCOUNT+'/assistant',
        ORDERS: ROUTES_BASE.ACCOUNT+'/orders',
        CARTS: ROUTES_BASE.ACCOUNT+'/carts',
        BANKROLL: ROUTES_BASE.ACCOUNT+'/cagnotte',
        BANKROLL_ORDER: ROUTES_BASE.ACCOUNT+'/cagnotte/commandes',
    },
    CATALOG: {
        PRODUCT: ROUTES_BASE.CATALOG+'/product',
        CATEGORY: ROUTES_BASE.CATALOGUE+'/category'
    },
    MODIFICATION: {
        CART: "/modification/cart",
        SHIPPING: "/modification/shipping",
        INFORMATION: "/modification/information",
        VALIDATION: "/modification/validation",
        CONFIRMATION: "/modification/confirmation"
    },
    JOBS: {
        BACKEND: ROUTES_BASE.JOBS+'/founding-engineer-back',
        GTM: ROUTES_BASE.JOBS+'/first-gtm',
    }
}

export default class RoutesHelper{

    static getMarketSearch = () => {
        return ROUTES_BASE.SEARCH
    }
    static getOrderRouteByID = (orderUuid: string) => {
        return `${ROUTES.ACCOUNT.ORDERS}/${orderUuid}`
    }
    
    static getOrderModificationCartRouteByID = (orderUuid: string) => {
        return `${this.getOrderRouteByID(orderUuid)}${ROUTES.MODIFICATION.CART}`
    }

    static getOrderModificationShippingRouteByID = (orderUuid: string) => {
        return `${this.getOrderRouteByID(orderUuid)}${ROUTES.MODIFICATION.SHIPPING}`
    }

    static getOrderModificationInformationRouteByID = (orderUuid: string) => {
        return `${this.getOrderRouteByID(orderUuid)}${ROUTES.MODIFICATION.INFORMATION}`
    }

    static getOrderModificationValidationRouteByID = (orderUuid: string) => {
        return `${this.getOrderRouteByID(orderUuid)}${ROUTES.MODIFICATION.VALIDATION}`
    }

    static getOrderModificationConfirmationRouteByID = (orderUuid: string) => {
        return `${this.getOrderRouteByID(orderUuid)}${ROUTES.MODIFICATION.CONFIRMATION}`
    }
    
    static getOrderTransactionRouteByID = (orderID: string, transactionUuid: string) => {
        return `${this.getOrderRouteByID(orderID)}/payment/${transactionUuid}`
    }
    
    static getCategoryRouteByPathName = (categoryPathName: string) => {
        return `${ROUTES_BASE.CATALOGUE}/${categoryPathName}`
    }
    
    static getSubCategoryRouteByPathName = (categoryPathName?: string, subCategoryPathName?: string) => {
        return categoryPathName && subCategoryPathName ? `${ROUTES_BASE.CATALOGUE}/${categoryPathName}/${subCategoryPathName}` : '';
    }

    static getProductRouteByProductType = (product: ProductItemType) => {
        return product.type === PRODUCT_TYPE.SINGLE ? RoutesHelper.getProductRouteByMarketItem(product.marketItem) : RoutesHelper.getCollectionRouteByMarketCollection(product.marketCollection)
    }

    static getProductRouteByMarketItem = (marketItem: MarketItem) => {
        return this.getProductRoute(marketItem.content.marketCategorySlug, marketItem.content.marketSubCategorySlug, marketItem.slug)
    }

    static getProductRoute = (marketCategorySlug: string, marketSubCategorySlug: string, marketItemSlug: string) => {
        return `${this.getSubCategoryRouteByPathName(marketCategorySlug, marketSubCategorySlug)}/${marketItemSlug}`
    }

    static getCollectionRoute = (marketCategorySlug: string, marketSubCategorySlug: string, marketCollectionSlug: string) => {
        return `${this.getSubCategoryRouteByPathName(marketCategorySlug, marketSubCategorySlug)}/collection/${marketCollectionSlug}`
    }

    static getStripePaymentConfirmationRoute = (orderUID: string, transactionUID: string) => {
        return `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.ACCOUNT.ORDERS}/${orderUID}?stripeTransactionPending=${transactionUID}`
    }

    static getCollectionRouteByMarketCollection = (marketCollection: MarketCollection) => {
        return this.getCollectionRoute(marketCollection.content.marketCategorySlug, marketCollection.content.marketSubCategorySlug, marketCollection.slug)
    }

    static getDefaultCatalogPage = () => {
        return RoutesHelper.getSubCategoryRouteByPathName('assises', 'chaises')
    }
}

export const ORDER_STEPS = [
    {
        title: t("GLOBALS.Step") + ' 1',
        content: t("CARTS.Steps.Cart")
    },
    {
        title: t("GLOBALS.Step") + ' 2',
        content: t("CARTS.Steps.Shipping")
    },
    {
        title: t("GLOBALS.Step") + ' 3',
        content: t("CARTS.Steps.Information")
    },
    {
        title: t("GLOBALS.Step") + ' 4',
        content: t("CARTS.Steps.Validation")
    }
];

export const DATE_STEPS = [
    {
        title: t("GLOBALS.Step") + ' 1',
        content: t("ORDERS.Steps.Delivery")
    },
    {
        title: t("GLOBALS.Step") + ' 2',
        content: t("ORDERS.Steps.Retrieval")
    }
];

export const SEDEO_ADDRESS = {
    Line1: "68 Av du Général Michel Bizot",
    Line2: "75012 Paris"
}

export enum PRODUCT_TYPE {
    SINGLE = 'single',
    COLLECTION = 'collection'
}
export enum STATUS {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
    DATA = 'data',
    EMPTY_STATE = 'empty_state'
}

export enum OrderStatus {
    OrderWaitingValidation = "waiting-validation",
    OrderWaitingBookingPayment = "waiting-booking-payment",
    OrderWaitingFinalDepositPayment = "waiting-final-deposit-payment",
    OrderWaitingUpdateValidation = "waiting-update-validation",
    OrderConfirmed = "confirmed",
    OrderWaitingBalancePayment = "waiting-balance-payment",
    OrderComplete = "completed",
    OrderCancelled = "cancelled",
}

export const OrderModificationLocalStorage = 'orderModification';