import ApiHelper, {HTTP_VERBS} from "../ApiHelper";
import {CreateCartFromRevenueResponse, GetVenueOrderResponse, GetVenueOrdersResponse, ListUserVenuesResponse} from "./VenueApiDataModel";

export default class VenueApi{
    private static readonly BASE_URL = '/venues';

    public async createCartFromVenue(venueCode: string): Promise<CreateCartFromRevenueResponse> {
        return (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${VenueApi.BASE_URL}/${venueCode}/cart`)).json()
    }

    public async listUserVenues(): Promise<ListUserVenuesResponse> {
        return (await ApiHelper.authenticatedFetch(HTTP_VERBS.GET,`${VenueApi.BASE_URL}`)).json()
    }

    public async getAllVenueOrders(): Promise<GetVenueOrdersResponse> {
        return (await ApiHelper.authenticatedFetch(HTTP_VERBS.GET,`${VenueApi.BASE_URL}/orders`)).json()
    }

    public async getVenueOrder(venueId: string, orderId: string): Promise<GetVenueOrderResponse> {
        return (await ApiHelper.authenticatedFetch(HTTP_VERBS.GET,`${VenueApi.BASE_URL}/${venueId}/orders/${orderId}`)).json()
    }

}