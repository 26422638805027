import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from "../../redux/snackbar/snackbar.actions";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../utils/use-typed-selector";

export type SnackbarType = "SUCCESS" | "ERROR" | "WARNING";

export const CustomSnackbar = () => {

    const snackState = useSelector((state: any) => state.snackbarReducer);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    useEffect(() => {
        setIsOpen(snackState.isOpen)
    }, [snackState.isOpen])

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isOpen}
            className={`theme--${appTheme}`}
            autoHideDuration={snackState.time ?? 3000}
            onClose={() => dispatch(closeSnackbar())}
        >
            <Alert
                severity={snackState.snackbarType.toLowerCase() as AlertColor}
                className={`snackbar-alert snackbar-alert-${snackState.snackbarType as SnackbarType}`}>
                {snackState.message}
            </Alert>
        </Snackbar>
    )
};