
// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const trackAddToCart = () => {
    if(window.fbq) {
        window.fbq('track', 'AddToCart')
    }
}

export const trackCompleteRegistration = () => {
    if(window.fbq) {
        window.fbq('track', 'CompleteRegistration')
    }
}

export const trackPurchase = (amount) => {
    if(window.fbq) {
        window.fbq('track', 'Purchase', {value: amount, currency: 'EUR'})
    }
}