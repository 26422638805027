export type THEME = 'light' | 'dark';

export interface ThemeState {
    appTheme: THEME
}

const themeReducerInitialState: ThemeState = {
    appTheme: 'light'
}

export enum EventActionType {
    TOGGLE_THEME = 'toggle_theme',
}

export interface ThemeToggle {
    type: EventActionType.TOGGLE_THEME;
    payload: {
        appTheme: THEME
    };
}

const themeReducer = (state: ThemeState = themeReducerInitialState, action: ThemeToggle) => {
    switch (action.type) {
        case EventActionType.TOGGLE_THEME: 
            return {
                ...state,
                appTheme: action.payload.appTheme
            };
        default:
            return state;
    }
}

export default themeReducer;