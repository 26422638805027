import { convertCartProductTypeToGoogleCartItem } from "../../utils/GoogleAnalyticsHelper";
import ApiHelper, { HTTP_VERBS } from "../ApiHelper";
import AddressesApi from "../addresses/AddressesApi";
import { GetOrCreateAddressResponse } from "../addresses/dto/GetOrCreateAddress";
import { AvailableTimeSlots } from "../models/datetime";
import { CartApiContract } from "./CartApiContract";
import { CartResponse, InitiateOrderResponse, SetHandlingRequest, SetInformationsRequest, SetItemRequest, SetTransportScheduleRequest, SetVenueDetailsRequest } from "./CartApiDataModel";
import AmplitudeHelper from "../../utils/AmplitudeHelper";
import * as fbq from "../../../src/utils/FacebookHelper";

export default class CartApi implements CartApiContract{
    public static readonly BASE_URL = '/carts/';

    public async get(cartId: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.GET,`${CartApi.BASE_URL}${cartId}`)).json()).data
    }

    public async createCartFromAddress(addressId: string): Promise<GetOrCreateAddressResponse> {
        return (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${AddressesApi.ADDRESSES_URL}${addressId}/cart`)).json()
    }

    public async createCartFromVenue(venueId: string): Promise<GetOrCreateAddressResponse> {
        return (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${AddressesApi.VENUES_URL}${venueId}/cart`)).json()
    }

    public async updateAddress(cartId: string, addressId: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}${AddressesApi.ADDRESSES_URL}${addressId}/`)).json()).data
    }

    public async updateVenue(cartId: string, addressId: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}${AddressesApi.VENUES_URL}${addressId}/`)).json()).data
    }

    public async setItem(cartId: string, itemId: string, request: SetItemRequest): Promise<CartResponse> {
        const result =  (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}/items/${itemId}/`, request)).json()).data as CartResponse
        const item = result.products.items.find(item => item.uuid === itemId)
        if(window.gtag_add_to_cart && item){
            window.gtag_add_to_cart(convertCartProductTypeToGoogleCartItem(item))
        }
        fbq.trackAddToCart()

        AmplitudeHelper.trackCartUpdate(result.venue_address.address.coordinates, result.products.items)
        return result
    }

    public async removeItem(cartId: string, itemId: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.DELETE,`${CartApi.BASE_URL}${cartId}/items/${itemId}/`)).json()).data
    }

    public async clearItems(cartId: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/items/clear`)).json()).data
    }

    public async setTransportSchedule(cartId: string, request: SetTransportScheduleRequest): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}/transport-schedule`, request)).json()).data
    }

    public async setHandlingDetails(cartId: string, request: SetHandlingRequest): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}/venue-handling`, request)).json()).data
    }

    public async setVenueDetails(cartId: string, request: SetVenueDetailsRequest): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}/venue-details`, request)).json()).data
    }

    public async setInformations(cartId: string, request: SetInformationsRequest): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.PUT,`${CartApi.BASE_URL}${cartId}/informations`, request)).json()).data
    }

    public async addDiscount(cartId: string, discountCode: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/discounts/${discountCode}`)).json()).data
    }

    public async removeDiscount(cartId: string, discountCode: string): Promise<CartResponse> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.DELETE,`${CartApi.BASE_URL}${cartId}/discounts/${discountCode}`)).json()).data
    }

    public async getAvailableTimeslots(cartId: string): Promise<AvailableTimeSlots> {
        return (await (await ApiHelper.unauthenticatedFetch(HTTP_VERBS.GET,`${CartApi.BASE_URL}${cartId}/time-slots`)).json()).data
    }

    public async validateItems(cartId: string): Promise<void> {
        await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/validate/items`)
    }

    public async validateTransport(cartId: string): Promise<void> {
        await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/validate/transport`)
    }

    public async validateInformations(cartId: string): Promise<void> {
        await ApiHelper.unauthenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/validate/informations`)
    }

    // Requires authentication
    public async setUser(cartId: string): Promise<CartResponse> {
        return (await (await ApiHelper.authenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/user`)).json()).data
    }

    // Requires authentication
    public async initiateOrder(cartId: string): Promise<InitiateOrderResponse> {
        return (await (await ApiHelper.authenticatedFetch(HTTP_VERBS.POST,`${CartApi.BASE_URL}${cartId}/order`)).json()).data
    }
}