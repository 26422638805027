import CartApi from "../../api/carts/CartApi";
import {CartResponse} from '../../api/carts/CartApiDataModel';
import {SpatialDataResult} from "../../typing/types";
import ProductsAPIHelper from "../../utils/api/products/ProductsAPIHelper";
import MeApi from "../../api/me/MeApi";
import VenueApi from "../../api/venues/VenueApi";
import {GetUserResponse} from "../../api/me/dto/GetUser";

export class Repository {

    constructor(private readonly meApi: MeApi,
                private readonly cartApi: CartApi,
                private readonly venueApi: VenueApi) {
    }

    public async getUserInfo(): Promise<GetUserResponse>{
        return await this.meApi.getUser()
    }

    public async createCartFromVenueCode(venueCode: string): Promise<{cart: CartResponse, spData: SpatialDataResult}>{
        const response = await this.venueApi.createCartFromVenue(venueCode)

        const cart = await this.cartApi.get(response.data.id)
        const spData: SpatialDataResult = await ProductsAPIHelper.getSpatialData(cart.venue_address.address.coordinates.latitude, cart.venue_address.address.coordinates.longitude)
        return {cart, spData}
    }

    // _________________________________________________________________________________________________________ PRIVATE

}