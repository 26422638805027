import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import 'i18n';
import {t} from 'i18next';
import {FC} from "react";
import {useTypedSelector} from '../../utils/use-typed-selector';

interface VenueReferrerConfirmationDialogProps {
    venueCode: string
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export const VenueReferrerConfirmationDialog: FC<VenueReferrerConfirmationDialogProps> = (props) => {

    const { venueCode, open, onCancel, onConfirm } = props;
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    return(
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={`event-modification-confirmation-dialog venue-dialog theme--${appTheme}`}
        >
            <DialogTitle className='dialog-title' id="alert-dialog-title">
                Salle détectée : {venueCode}
            </DialogTitle>

            <DialogContent className='dialog-content'>
                <div className='venue-dialog-body'>
                    <p>Nous avons détecté une référence vers cette salle dans le lien que vous venez d&apos;entrer : <strong>{venueCode}</strong></p>
                    <p>Souhaitez-vous remplacer la salle actuellement sélectionnée par celle-ci ?</p>
                </div>
            </DialogContent>

            <DialogActions className='dialog-actions'>

                <Button variant="text"
                        className="sedeo-button-text"
                        onClick={onCancel}>
                    {t('BUTTONS.KeepCurrentVenue')}
                </Button>

                <Button variant="contained"
                        className="sedeo-button-rounded"
                        onClick={onConfirm}>
                    {t("BUTTONS.ConfirmChangeVenue")}
                </Button>

            </DialogActions>
        </Dialog>
    )
}