export const grey = {
  900: '#2B3445', // Main Text
  800: '#373F50', // Paragraph
  700: '#4B566B',
  600: '#7D879C', // Low Priority form Title/Text
  500: '#AEB4BE',
  400: '#DAE1E7', // Border
  300: '#E3E9EF',
  200: '#F3F5F9', // Line Stroke
  100: '#F6F9FC',
}

export const primary = {
  100: '#00ffb3',
  200: '#00ffb3',
  300: '#00fa95',
  400: '#00f282',
  500: '#00ffb3',
  600: '#00d961',
  700: '#00c453',
  800: '#00b246',
  900: '#008f2f',
  main: '#00D2B6',
}

export const secondary = {
  100: '#e8e8ee',
  200: '#b9bacb',
  300: '#8a8ca8',
  400: '#5b5d85',
  500: '#213861',
  600: '#555555',
  700: '#101340',
  800: '#0e1138',
  900: '#0c0e30',
  main: '#555555',
  dark: '#0c0e30',
}

export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560',
  dark: '#FF1111',
}

export const success = {
  100: '#E7F9ED',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0b7724',
  main: 'rgb(51, 208, 103)',
}

export const warning = {
  main: '#FFCD4E',
  contrastText: '#FFFFFF',
}

export const SEDEO_PALETTE = {
  white: '#FFFFFF',
  black: '#000000',
  disabledBackground: '#7b8ba9',
  disabledText: '#C4C4C4',
  lightBackground: '#E5E5E5',
  darkText: '#333333',
  lightBlue: '#096ec8',
  mediumGrey: '#555555',
  lighterGrey: '#909090',
  purpleColor: '#7755cc',
  sedeoMainColor: primary.main,
  sedeoSecondaryColor: secondary[500],
  navIcon: '#6a748a',
  sedeoLightOrange: '#FFB46F',
  error: '#FF2929',
  title: '#da532c',
  lightError: error[400],
  inProcess: '#e8e8ee',
  delivery: '#FDF5E1',
  complete: '#E9F8E2',
}

export const SNACKBAR_COLORS = {
  SUCCESS: {
    BG_COLOR: primary[500],
    TEXT_COLOR: secondary[500]
  },
  ERROR: {
    BG_COLOR: error.main,
    TEXT_COLOR: warning.contrastText
  },
  WARNING: {
    BG_COLOR: warning.main,
    TEXT_COLOR: warning.contrastText
  }
}