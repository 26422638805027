export enum EventActionType {
    SET_NEW_EVENT = 'SET_NEW_EVENT',
    UPDATE_EVENT_INFO = 'UPDATE_EVENT_INFO',
    UPDATE_CART = 'UPDATE_CART',
    UPDATE_MAX_ORDERING_STEP = 'UPDATE_MAX_ORDERING_STEP',

    SET_AVAILABLE_ITEMS = 'SET_AVAILABLE_ITEMS',

    ADD_ITEMS_TO_CART = 'ADD_ITEMS_TO_CART',
    SET_CART_ITEM_QUANTITY = 'SET_CART_ITEM_QUANTITY',
    DECREASE_ITEMS_FROM_CART = 'DECREASE_ITEMS_FROM_CART',
    REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART',

    SET_ORDER_SHIPPING_INFO = 'SET_ORDER_SHIPPING_INFO',
    SET_ORDER_INFORMATION_INFO = 'SET_ORDER_INFORMATION_INFO',

    RESUME_EVENT = 'RESUME_EVENT',

    RESET_ALL = 'RESET_ALL',

}