import * as amplitude from '@amplitude/analytics-browser';
import { flush, identify, Identify, setUserId, track } from "@amplitude/analytics-browser";
import { debounce } from "lodash";
import { SetHandlingRequest } from '../api/carts/CartApiDataModel';
import { PricedItem } from '../api/models/item';
import { OrderCartViewModel } from "../features/order/cart/view-model";
import {Coordinates} from "../api/models/geolocation";

export enum EventDataFormDialogRestartOrigin{
    TOP, VALUES, SCREENSHOT, REVIEWS, BOTTOM
}

export enum EventDataFormDialogStartFromScratchOrigin{
    PRODUCT_DETAIL_PAGE, MARKET_SUB_CATEGORY_LIST, SIDE_CART
}

export enum MarketCategoryClickOrigin{
    HOME, BREADCRUMB, LANDING
}

export enum MarketSubCategoryClickOrigin{
    MARKET_CATEGORY_PAGE, BREADCRUMB, TOP_BAR_MAIN_ITEM, TOP_BAR_HOVER_ITEM,
    MARKET_ITEM_PAGE_SUGGESTED_CATEGORY
}

export enum MarketItemClickOrigin{
    MARKET_SUB_CATEGORY_PAGE, MARKET_ITEM_PAGE_SUGGESTED_PRODUCTS, SIDE_CART, MARKET_COLLECTION_PRODUCTS, SEARCH
}

export enum SeeCartRecapOrigin{
    HEADER, SIDE_MENU
}

export interface CartLead {
    mail: string,
    cartId: string
    eventStartDate: Date,
    eventEndDate: Date,
    addressLabel: string,
    lat: number,
    lng: number,
    productValue: number,
    maxOrderingStepSeen: number,
}
export enum OrderingStep {
    NONE, SHIPPING, INFORMATIONS, VALIDATION, ORDERED
}

export default class AmplitudeHelper{
    static initAmplitude(key: string){
        amplitude.init(key, {
            defaultTracking: true
        });
    }

    // _________________________________________________________________________________________________ USER PROPERTIES

    static logOut(){
        amplitude.reset()
    }

    // __________________________________________________________________________________________________________ EVENTS


    /**
     * User is in home
     */
    static trackUserIsInHome(){
        track('Home-Opened');
        flush()
    }

    /**
     * User clicks on 'Loueur - Devenir partenaire' in footer
     */
    static trackFooterBecomePartner(){
        track('Footer-BecomePartner');
    }

    /**
     * Tracks under the same event what the user does when coming in the home.
     * This might seem redundant with trackMarketCategoryClick below, but Amplitude offers better UI
     * when filtering properties of the same event
     */
    static trackHomeCTA(actionName: string){
        let eventProperties = { action: actionName}
        track('Home-AllCTA', eventProperties);
    }

    /**
     * User opens the EventDataFormDialog to re-start a new order from the / page. Happens when user already had an event
     * in the redux state
     * We also track a general "EventDataFormDialog-Shown" to group "EventDataFormDialog-ReStartOrder" and "EventDataFormDialog-NewOrder" events
     */
    static trackEventHomeNewOrderClick(origin: EventDataFormDialogRestartOrigin){
        let eventProperties = {}
        switch (origin) {
            case EventDataFormDialogRestartOrigin.TOP:
                eventProperties = { origin: 'TOP'};
                break;
            case EventDataFormDialogRestartOrigin.VALUES:
                eventProperties = { origin: 'VALUES'};
                break;
            case EventDataFormDialogRestartOrigin.SCREENSHOT:
                eventProperties = { origin: 'SCREENSHOT'};
                break;
            case EventDataFormDialogRestartOrigin.REVIEWS:
                eventProperties = { origin: 'REVIEWS'};
                break;
            case EventDataFormDialogRestartOrigin.BOTTOM:
                eventProperties = { origin: 'BOTTOM'};
                break;
            default: const _exhaustiveCheck: never = origin;

        }
        track('EventDataFormDialog-ReStartOrder', eventProperties)
        track('EventDataFormDialog-Shown')
    }

    /**
     * User opens the EventDataFormDialog to start a new event.
     * We also track a general "EventDataFormDialog-Shown" to group "EventDataFormDialog-ReStartOrder" and "EventDataFormDialog-NewOrder" events
     */
    static trackEventStartEventClick(origin: EventDataFormDialogStartFromScratchOrigin){
        let eventProperties = {}
        switch (origin) {
            case EventDataFormDialogStartFromScratchOrigin.PRODUCT_DETAIL_PAGE:
                eventProperties = { origin: 'PRODUCT_DETAIL_PAGE'};
                break;
            case EventDataFormDialogStartFromScratchOrigin.MARKET_SUB_CATEGORY_LIST:
                eventProperties = { origin: 'MARKET_SUB_CATEGORY_LIST'};
                break;
            case EventDataFormDialogStartFromScratchOrigin.SIDE_CART:
                eventProperties = { origin: 'SIDE_CART'};
                break;
            default: const _exhaustiveCheck: never = origin;
        }
        track('EventDataFormDialog-NewOrder', eventProperties)
        track('EventDataFormDialog-Shown')
    }

    /**
     * User clicks on a component that will him to a /catalogue/[categoryId] page
     */
    static trackMarketCategoryClick(marketCategorySlug: string, origin: MarketCategoryClickOrigin){
        let originValue
        switch (origin) {
            case MarketCategoryClickOrigin.HOME:
                originValue = "HOME"
                break;
            case MarketCategoryClickOrigin.BREADCRUMB:
                originValue = "BREADCRUMB"
                break;
            case MarketCategoryClickOrigin.LANDING:
                originValue = "LANDING"
                break;
            default: const _exhaustiveCheck: never = origin;
        }
        track('MarketCategoryClick', {
            slug: marketCategorySlug,
            origin: originValue
        });
    }


    /**
     * When the user loads the /catalogue/[categoryId] page
     */
    static trackMarketCategoryPageSeen(marketCategorySlug: string){
        track('MarketCategory-Seen', {
            marketCategorySlug: marketCategorySlug,
        });
    }

    /**
     * User clicks on a component that will him to a /catalogue/[categoryId]/[subCategoryId] page
     */
    static trackMarketSubCategoryClick(marketSubCategorySlug: string, origin: MarketSubCategoryClickOrigin){
        let originValue
        switch (origin) {
            case MarketSubCategoryClickOrigin.MARKET_CATEGORY_PAGE:
                originValue = "MARKET_CATEGORY_PAGE"
                break;
            case MarketSubCategoryClickOrigin.BREADCRUMB:
                originValue = "BREADCRUMB"
                break;
            case MarketSubCategoryClickOrigin.TOP_BAR_MAIN_ITEM:
                originValue = "TOP_BAR_MAIN_ITEM"
                break;
            case MarketSubCategoryClickOrigin.TOP_BAR_HOVER_ITEM:
                originValue = "TOP_BAR_HOVER_ITEM"
                break;
            case MarketSubCategoryClickOrigin.MARKET_ITEM_PAGE_SUGGESTED_CATEGORY:
                originValue = "MARKET_ITEM_PAGE_SUGGESTED_CATEGORY"
                break;
            default: const _exhaustiveCheck: never = origin;
        }
        track('MarketSubCategoryClick', {
            slug: marketSubCategorySlug,
            origin: originValue
        });
    }

    /**
     * When the user loads the /catalogue/[categoryId]/[subCategoryId] page
     */
    static trackMarketSubCategoryPageSeen(marketCategorySlug: string, marketSubCategorySlug: string){
        track('MarketSubCategory-Seen', {
            marketCategorySlug: marketCategorySlug,
            marketSubCategorySlug: marketSubCategorySlug,
        });
    }

    /**
     * User clicks on a component that will him to a /catalogue/[categoryId]/[subCategoryId]/[itemId] page
     */
    static trackMarketItemClick(marketItemSlug: string, origin: MarketItemClickOrigin){
        let originValue
        switch (origin) {
            case MarketItemClickOrigin.MARKET_SUB_CATEGORY_PAGE:
                originValue = "MARKET_SUB_CATEGORY_PAGE"
                break;
            case MarketItemClickOrigin.MARKET_ITEM_PAGE_SUGGESTED_PRODUCTS:
                originValue = "MARKET_ITEM_PAGE_SUGGESTED_PRODUCTS"
                break;
            case MarketItemClickOrigin.SIDE_CART:
                originValue = "SIDE_CART"
                break;
            case MarketItemClickOrigin.MARKET_COLLECTION_PRODUCTS:
                originValue = "MARKET_COLLECTION_PRODUCTS"
                break;
            case MarketItemClickOrigin.SEARCH:
                originValue = "SEARCH"
                break;

            default: const _exhaustiveCheck: never = origin;
        }
        track('MarketItemClick', {
            slug: marketItemSlug,
            origin: originValue
        });
    }
    /**
     * When the user loads the /catalogue/[categoryId]/[subCategoryId]/[itemId] page
     */
    static trackMarketItemPageSeen(marketCategorySlug: string, marketSubCategorySlug: string, marketItemSlug: string){
        track('MarketItem-Seen', {
            marketCategorySlug: marketCategorySlug,
            marketSubCategorySlug: marketSubCategorySlug,
            marketItemSlug: marketItemSlug
        });
    }

    /**
     * User validated a location in the EventDataFormDialog.
     */
    static trackEventDataFormDialogLocationAdded(){
        track('EventDataFormDialog-LocationAdded');
    }

    /**
     * User validates the EventDataFormDialog
     */
    static trackEventDataFormDialogValidated(lat: number, lng: number, readableAddress: string){
        let eventProperties = {
            position: JSON.stringify({lat: lat, lng: lng, address: readableAddress}, null, 0)
        }

        track('EventDataFormDialog-Validated', eventProperties);
    }

    /**
     * User added at least an item to his cart.
     */
    static trackUserAddedItemInCart = debounce( function(){
        track('Cart-ItemAdded');
    }, 2000)

    /**
     * Every time a cart has its item updated. This allow us to position carts on a map to know where is the demand
     * Eventually it should be done directly in the back-end when the client cart will be entirely synced with the remote cart
     * (whether the user is logged in or not)
     */
    static trackCartUpdate = debounce(function (coordinates: Coordinates, cartItems: PricedItem[]) {
        let content = JSON.stringify(cartItems.map((product) => {
            return {n: product.uuid, q: product.quantity, p: product.exc_tax_unit_price}
        }), null, 0)

        let eventProperties = {
            position: JSON.stringify({lat: coordinates.latitude, lng: coordinates.longitude}, null, 0),
            date: Date.now(),
            cart: content.length > 1023 ? '' : content // Amplitude does not accept properties that are longer than 1024 characters
        }
        track('CartData-Update', eventProperties);
    }, 5000);


    /**
     * Send a lead with the cart content and a way to contact the owner. It will be done in our back-end directly in the future but for now
     * we send it to Amplitude
     */
    static trackCartLead = debounce(function (cartLead: CartLead) {
        let eventProperties = {
            mail: cartLead.mail,
            cartId: cartLead.cartId,
            lastUpdate: Date.now(),
            data:JSON.stringify({
                addressLabel: cartLead.addressLabel,
                startDate: cartLead.eventStartDate.getTime(),
                endDate: cartLead.eventEndDate.getTime(),
                lat: cartLead.lat,
                lng: cartLead.lng,
                productValue: cartLead.productValue,
                maxOrderingStep: OrderingStep[cartLead.maxOrderingStepSeen],
            }, null, 0)
        }
        track('CartLead', eventProperties);
    }, 1000);


    /**
     * User added at least an item to his cart.
     */
    static trackCartSyncTimer(timer: number, cartValue: number, distinctItems: number){
        let eventProperties = {
            timer: timer,
            cartValue: cartValue,
            distinctItems: distinctItems
        }
        track('CartSync', eventProperties);
    }


    /**
     * User clicks to see the cart recap
     */
    static trackSeeCartRecap(origin: SeeCartRecapOrigin, cartValue: number, cartItems: PricedItem[]){
        let originValue
        switch (origin) {
            case SeeCartRecapOrigin.HEADER:
                originValue = 'HEADER'
                break;
            case SeeCartRecapOrigin.SIDE_MENU:
                originValue = 'SIDE_MENU'
                break;
        }

        let eventProperties = {
            origin: originValue,
            cartValueWithoutDuration: cartValue,
            itemQuantity: cartItems.reduce((total, prev) => total + prev.quantity, 0),
            distinctItems: cartItems.length
        }

        track('Cart-SeeRecap', eventProperties);
    }


    /**
     * User confirms cart from /order/cart:
     */
    static trackCartConfirmed(cart: OrderCartViewModel){

        let eventProperties = {
            itemQuantity: cart.cartContent.reduce((total, prev) => total + prev.items.reduce((t, p) => t + p.quantity, 0), 0),
            distinctItems: cart.cartContent.reduce((total, prev) => total + prev.items.length, 0),
        }
        track('Cart-Confirmed', eventProperties);
    }

    /**
     * User confirms /order/shipping:
     */
    static trackShippingConfirmed(handlingRequest: SetHandlingRequest){
        track('Shipping-Confirmed', handlingRequest);
    }

    /**
     * User confirms /order/shipping:
     */
    static trackShippingInformation(){
        track('Shipping-Information-Confirmed');
    }

    /**
     * User validates the order
     */
    static trackOrderValidated(orderPriceTTC: number){
        let eventProperties = {
            cartValue: orderPriceTTC,
        }
        track('Order-Validated', eventProperties);
    }

    // ---------------- LoginRegister
    static trackLoginRegisterOpened(){
        track('LoginRegister-Opened');
    }

    static trackLoginRegisterUserRegistered(){
        track('LoginRegister-Register');
    }

    static trackLoginRegisterUserLoggedIn(mail: string){
        setUserId(mail)
        track('LoginRegister-Login');
    }

    // ---------------- Transaction
    static trackTransactionPageOpened(){
        track('OrderTransaction-Opened');
    }

    static trackTransactionPaid(amountPaid: number, isOrderPaid: boolean){
        let eventProperties = {
            amount: amountPaid
        }
        track(' OrderTransaction-Paid', eventProperties);
        let identifyObject = new Identify()
        identifyObject.add('orderAmountPending', -amountPaid)
        if(isOrderPaid){
            identifyObject.add('orderPending', -1)
        }
        identify(identifyObject)
    }

    // ---------------- OrderModification
    static trackOrderModificationOpened(){
        track('OrderModification-Opened');
    }

    static trackOrderModificationCartConfirmed(){
        track('OrderModificationCart-CartConfirmed');
    }

    static trackOrderModificationShippingConfirmed(){
        track('OrderModificationShipping-ShippingConfirmed');
    }

    static trackOrderModificationInformationConfirmed(){
        track('OrderModificationShipping-InformationConfirmed');
    }

    static trackOrderModificationConfirmed(){

        track('OrderModification-ModificationConfirmed');
    }

    // ---------------- Market
    /**
     * When the user loads the /search page
     */
    static trackSearchPageSeen(){
        track('SearchPage-Seen');
    }

    static trackMarketSearchLoadingTime(time: number){
        track('SearchPage-LoadingTime', {
            time: time
        });
    }

    static trackMarketSearch(input: string){
        let eventProperties = {
            input: input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }

        track('Marketplace-SearchInput', eventProperties);
    }

    // ---------------- Blog
    static trackBlogSeen(){
        track('Blog-Seen');
    }

    static trackBlogCategorySeen(categorySlug: string){
        let eventProperties = {
            categorySlug: categorySlug
        }

        track('BlogCategory-Seen', eventProperties);
    }

    static trackBlogPostSeen(categorySlug: string, blogPostSlug: string){
        let eventProperties = {
            categorySlug: categorySlug,
            postSlug: blogPostSlug
        }

        track('BlogPost-Seen', eventProperties);
    }

    static trackBlogPostTitleCTAClicked(categorySlug: string, blogPostSlug: string){
        let eventProperties = {
            categorySlug: categorySlug,
            postSlug: blogPostSlug,
            cta: 'title'
        }

        track('BlogPost-CTA-Click', eventProperties);
    }

    static trackBlogPostImageBannerClicked(categorySlug: string, blogPostSlug: string, trackerId: string){
        let eventProperties = {
            categorySlug: categorySlug,
            postSlug: blogPostSlug,
            cta: trackerId
        }

        track('BlogPost-ImageBanner-Click', eventProperties);
    }

    static trackBlogUserSubscribedToNewsletter(categoryOrigin: string){
        let eventProperties = {
            categoryOrigin: categoryOrigin,
        }

        track('Blog-NewsletterSubscription', eventProperties);
    }

    // ---------------- Landing
    static trackLandingPageSeen(page: string){
        let eventProperties = {
            page: page
        }

        track('LandingPage-Seen', eventProperties);
    }

    static trackLandingPageCTAClicked(page: string, cta: string){
        let eventProperties = {
            page: page,
            cta: cta
        }

        track('LandingPage-CTA-Click', eventProperties);
    }
}