import {KeratinSingleton} from "../auth/KeratinSingleton";

export class ApiError extends Error {
    public statusCode: number;
    public description: string;

    constructor(statusCode: number, description: string) {
        super(description);
        this.name = 'ApiError';
        this.statusCode = statusCode;
        this.description = description;
    }
}

const AUTHENTICATED_PATH = "/authenticated"
export const HTTP_VERBS = {
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};
export default class ApiHelper {
    static async authenticatedFetch(verb: string, path: string, body?: any) {
        const accessToken = KeratinSingleton.getInstance().getAccessToken()

        let res = await this.executeApiRequest(verb, AUTHENTICATED_PATH+path, accessToken, body)

        if (res.status != 401) {
            return this.handleErrors(res)
        }

        const newToken = await KeratinSingleton.getInstance().getNewAccessToken()
        res = await this.executeApiRequest(verb, AUTHENTICATED_PATH+path, newToken, body)

        return this.handleErrors(res)
    }

    static async unauthenticatedFetch(verb: string, path: string, body?: any) {
        return this.handleErrors(await this.executeApiRequest(verb, path, undefined, body))
    }

    private static executeApiRequest(verb: string, path: string, accessToken: string|undefined, body: any) {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(accessToken !== null){
            headers = Object.assign(headers, {Authorization: `Bearer ${accessToken}`})
        }
        return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`, {
            method: verb,
            headers: headers,
            body: JSON.stringify(body),
        })
    }

    private static async handleErrors(response: Response) {
        if (!response.ok) {
            // Enhanced error handling based on response status, content, etc.
            throw new ApiError(response.status, await response.text());
        }
        return response;
    }
}
