import {Coordinates} from "./geolocation";
import {t} from "i18next";

export interface Address {
    google_place_id: string;
    line1: string;
    line2: string;
    line3: string;
    zip_code: string;
    city: string;
    coordinates: Coordinates;
}
export enum AccessType {
    GROUNDFLOOR = 0,
    WITHELEVATOR = 1,
    WITHOUTELEVATOR = 2,
}

export interface BillingAddress {
    has_different_billing_address: boolean;
    address: Address|null; // Optional, as it depends on has_different_billing_address
}

export interface VenueDetails {
    venue_name: string;
    room_name: string;
    digicode: string;
    building_name: string;
    misc: string;
    licence_plate_required: boolean;
}

export interface OtherDetails {
    company_name: string;
    misc: string
}

export interface Details {
    company_name: string;
    misc: string;
}

export interface HandlingDetails {
    curbside: boolean;
    floor: number;
    handling_distance: number;
    has_elevator: boolean;
    has_flat_access: boolean;
}

export interface VenueAddress {
    address: Address;
    venue_details: VenueDetails;
    code: string
    handling_details: HandlingDetails;
    known: boolean
}

export const getAddressAccessType = (floor: number, hasElevator: boolean): AccessType => {
    if(floor > 0 && hasElevator) return AccessType.WITHELEVATOR
    if(floor > 0 && !hasElevator) return AccessType.WITHOUTELEVATOR
    else return AccessType.GROUNDFLOOR
}

export const getReadableAddressAccessType = (type: AccessType): string  => {
    switch(type){
        case AccessType.GROUNDFLOOR: return t("ORDERS.Overview.DeliveryType.Ground")
        case AccessType.WITHELEVATOR: return t("ORDERS.Overview.DeliveryType.FloorWithElevator")
        case AccessType.WITHOUTELEVATOR: return t("ORDERS.Overview.DeliveryType.floorWithoutElevator")
    }
}