import {PricedItem} from "../api/models/item";

export interface GoogleCartItem {
    item_id: string,
    item_name: string,
    price: number,
    quantity: number
}

export interface GoogleAddToCartParams {
    currency: string;
    value: number;
    items: GoogleCartItem[];
}

export const convertCartProductTypeToGoogleCartItem = (item: PricedItem): GoogleAddToCartParams => {
    return{
        currency: "EUR",
        value: item.exc_tax_total_price,
        items: [{
            item_id: item.uuid,
            item_name: item.name,
            price: item.exc_tax_unit_price,
            quantity: item.quantity
        }]
    }
}