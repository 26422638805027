import ApiHelper, {HTTP_VERBS} from "../ApiHelper";
import {MeApiContract} from "./MeApiContract";
import {GetUserResponse} from "./dto/GetUser";
import {UpdateUserRequest} from "./dto/UpdateUser";

export default class MeApi implements MeApiContract{
    private static readonly BASE_URL = '/me';

    public async getUser(): Promise<GetUserResponse> {
        return (await ApiHelper.authenticatedFetch(HTTP_VERBS.GET,`${MeApi.BASE_URL}`)).json()
    }
    public async updateUser(request: UpdateUserRequest): Promise<void> {
        await ApiHelper.authenticatedFetch(HTTP_VERBS.PUT,`${MeApi.BASE_URL}`, request)
    }

}