import 'i18n';
import { t } from 'i18next';
import Link from "next/link";
import { FC } from "react";
import { ReactSVG } from "react-svg";
import AmplitudeHelper from "../../utils/AmplitudeHelper";
import { SEDEO_ADDRESS } from "../../utils/consts";
import StarIcon from '@mui/icons-material/Star';


interface FooterProps{

}
export const Footer: FC<FooterProps> = (props) => {

    return (
        <footer className="footer-container">
            <div className="footer-content">

                <div className="left">
                    <Link className="footer-content-logo" href="/">
                        <img src="/assets/sedeo-logo-white.svg" width={201} height={84} title="sedeo-logo"  alt="Home"/>
                    </Link>

                    <p className="address-line">{SEDEO_ADDRESS.Line1}</p>
                    <p className="address-line">{SEDEO_ADDRESS.Line2}</p>
                </div>

                <div className="links-container">

                    <div className="quick-links">

                        <p className="footer-link-title">
                            {t("FOOTER.QuickLinks")}
                        </p>

                        <div className="footer-link hoverable">
                            <Link target="_blank" href="https://www.notion.so/sedeo/Conditions-G-n-rales-d-Utilisation-7a624dbce92e490aad88f09f32dcf545" rel="nofollow">
                                {t("FOOTER.TermsAndConditions")}
                            </Link>
                            {/* <div className="sedeo-rectangle"></div> */}
                        </div>

                        <div className="footer-link hoverable">
                            <Link target="_blank" href="https://www.notion.so/Conditions-G-n-rales-de-Vente-78f6f7fc657642e0abd64cd71a6340c9" rel="nofollow">
                                {t("FOOTER.GeneralConditions")}
                            </Link>
                            {/* <div className="sedeo-rectangle"></div> */}
                        </div>

                        <div className="footer-link hoverable">
                            <Link target="_blank" href="https://mt52o8g1ijd.typeform.com/to/AoLMqfO4" rel="nofollow" onClick={() => AmplitudeHelper.trackFooterBecomePartner()}>
                                {t("FOOTER.BecomePartner")}
                            </Link>
                            {/* <div className="sedeo-rectangle"></div> */}
                        </div>

                        <div className="footer-link hoverable">
                            <a href={`mailto:contact@sedeo.fr`} rel="nofollow">
                                {t("FOOTER.ContactUs")}
                            </a>
                            {/* <div className="sedeo-rectangle"></div> */}
                        </div>

                        <div className="footer-link hoverable">
                            <Link href={`${process.env.NEXT_PUBLIC_BASE_URL}/blog`}>
                                Blog
                            </Link>
                            {/* <div className="sedeo-rectangle"></div> */}
                        </div>
                        
                    </div>

                </div>

                <div className="social-container">
                    <div className='social-wrapper'>
                        <div className='rating'>
                            <StarIcon/><div><span>4.9/5</span> avis clients</div>
                        </div>

                        <p className="all-rights-reserved">
                            {t("FOOTER.AllRightsReserved")} {(new Date()).getFullYear()}
                        </p>

                        <div className='social-icons'>
                            <Link className='social-icon hoverable email' href='mailto:contact@sedeo.fr' aria-label="Send an email to Sedeo">
                                <ReactSVG src="/assets/social-icons/email.svg" title="sedeo-email"/>
                            </Link>
                            
                            <Link className='social-icon hoverable linkedin' href='https://www.linkedin.com/company/sedeo-platform' aria-label="visiter Sedeo sur Linkedin" target='_blank'>
                                <ReactSVG src="/assets/social-icons/linkedin.svg" title="sedeo-linkedin"/>
                            </Link>
                            
                            <Link className='social-icon hoverable instagram' href='https://instagram.com/sedeo.fr' aria-label="visiter Sedeo sur Instagram" target='_blank'>
                                <ReactSVG src="/assets/social-icons/instagram.svg" title="sedeo-instagram"/>
                            </Link>

                            <Link className='social-icon hoverable pinterest' href='https://www.pinterest.com/sedeofr' aria-label="visiter Sedeo sur Pinterest" target='_blank'>
                                <ReactSVG src="/assets/social-icons/pinterest.svg" title="sedeo-pinterest"/>
                            </Link>

                            <Link className='social-icon hoverable facebook' href='https://www.facebook.com/people/Sedeo/100094572626849' aria-label="visiter Sedeo sur Facebook" target='_blank'>
                                <ReactSVG src="/assets/social-icons/facebook.svg" title="sedeo-facebook"/>
                            </Link>

                            <Link className='social-icon hoverable youtube' href='https://www.youtube.com/@sedeofr' aria-label="visiter Sedeo sur Youtube" target='_blank'>
                                <ReactSVG src="/assets/social-icons/youtube.svg" title="sedeo-youtube"/>
                            </Link>
                        </div>
                    </div>
                </div>
                
            </div>

        </footer>
    );
}
