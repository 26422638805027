import { BASE_TOPBAR_HEIGHT } from "../../utils/consts";

interface TopBarHeightAction{
    type: 'setTopBarHeight'
    payload:{
        topBarHeight: number;
    }
}

interface TopbarState {
    height: number
}

export const topbarInitialState: TopbarState = {
    height: BASE_TOPBAR_HEIGHT
};

const topbarReducer = (state: TopbarState = topbarInitialState, action: TopBarHeightAction) => {
    return action.payload?.topBarHeight ? { height: action.payload.topBarHeight } : state;
}

export const changeTopBarHeight = (height: number): TopBarHeightAction => {
    return {
        type: 'setTopBarHeight',
        payload:{ topBarHeight: height }
    }
}

export default topbarReducer;
