import { CartResponse } from "../../api/carts/CartApiDataModel";
import { OrderInformationInfo } from "../../typing/types";
import { GooglePlaceType } from "../../ui/eventDataForm/location/GoogleApiLocationForm";
import * as EventActionContract from './event.actions-contract';
import { EventState } from "./event.reducer";
import { EventActionType } from "./event.types";
import { EventDataType } from "./EventDataType";

// #FEATURE_MULTIRENTER

export const setNewEvent = (cart: CartResponse, googleSelectedPlace: GooglePlaceType|null,
    SPToken: string, defaultMarketRedirectionUrl: string): EventActionContract.SetNewEventAction => {
    return {
        type: EventActionType.SET_NEW_EVENT,
        payload: {
            cart, googleSelectedPlace, SPToken, defaultMarketRedirectionUrl
        },
    };
};

export const updateMaxOrderingStep = (step: number): EventActionContract.UpdateMaxOrderingStepAction => {
    return {
        type: EventActionType.UPDATE_MAX_ORDERING_STEP,
        payload: {
            step: step
        }
    }
}

export const updateCart = (cart: CartResponse): EventActionContract.UpdateCartAction => {
    return {
        type: EventActionType.UPDATE_CART,
        payload: {
            cart
        },
    };
};

export const updateEventInfo = (eventData: EventDataType): EventActionContract.UpdateEventInfoAction => {
    return {
        type: EventActionType.UPDATE_EVENT_INFO,
        payload: {
            eventData
        },
    };
};

export const setOrderInformationInfo = (orderInformationInfo: OrderInformationInfo): EventActionContract.UpdateOrderInformationAction => {
    return {
        type: EventActionType.SET_ORDER_INFORMATION_INFO,
        payload: {
            orderInformationInfo
        },
    };
};


export const resumeEvent = (eventState: EventState): EventActionContract.ResumeEventAction => {
    return {
        type: EventActionType.RESUME_EVENT,
        payload: {
            eventState
        },
    };
};

export const resetEventReducer = (): EventActionContract.ResetAllAction => {
    return {
        type: EventActionType.RESET_ALL,
        payload: {}
    };
};


