import {SessionActionType} from "./session.types";

import * as SessionActionContract from './session.actions-contract';
import {GetUserResponse} from "../../api/me/dto/GetUser";

export const setUserLoggedIn = (user: GetUserResponse): SessionActionContract.SignInSuccessAction => {
    return {
        type: SessionActionType.SIGN_IN_SUCCESS,
        payload: {
            user,
        },
    };
};

export const logOut = (): SessionActionContract.LogOutAction => {
    return {
        type: SessionActionType.LOG_OUT,
        payload: {}
    };
};