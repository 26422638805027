import { SnackbarType } from "../../ui/common/CustomSnackbar";

export enum SnackbarActionType {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE'
}

export interface OpenSnackbar{
    type: SnackbarActionType.OPEN;
    payload:{
        snackbarType: SnackbarType;
        message: string;
        time: number|undefined;
    }
}

export interface CloseSnackbar{
    type: SnackbarActionType.CLOSE;
    payload: {}
}

export const openSnackbar = (snackbarType: SnackbarType, message: string, time?: number): OpenSnackbar => {
    return {
        type: SnackbarActionType.OPEN,
        payload:{
            snackbarType,
            message,
            time
        }
    }
}

export const closeSnackbar = (): CloseSnackbar => {
    return {
        type: SnackbarActionType.CLOSE,
        payload: {}
    }
}

export type SnackbarAction =
    | OpenSnackbar
    | CloseSnackbar