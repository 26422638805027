import {useState} from "react";
import {useCookies} from "react-cookie";
import {useDialog} from "../hooks/use-dialog";

export const useMainAppState = () => {

    const [isUserInLoginPage, setIsUserInLoginPage] = useState<boolean>(false);
    const [isUserInCartAssistantPage, setIsUserInCartAssistantPage] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies(['OptInOut']); // Don't use "cookies" directly in rendering as it will produce hydration error. See https://stackoverflow.com/questions/74579757/how-to-fix-error-hydration-failed-because-the-initial-ui-does-not-match-what-wa
    const [hasOptInOutCookieValue, setHasOptInOutCookieValue] = useState<boolean>(true) // True by default so that the cookie dialog is not rendered on each first-render. We check if we have the user agreement when the component is mounting.

    const [venueReferrerCode, setVenueReferrerCode] = useState<string>('');
    const [openVenueReferrerConfirmDialog, handleOpenVenueReferrerConfirmDialog, handleCancelVenueReferrerConfirmDialog] = useDialog()

    return {isUserInLoginPage, setIsUserInLoginPage,
        isUserInCartAssistantPage, setIsUserInCartAssistantPage,
        cookies, setCookie, removeCookie,
        hasOptInOutCookieValue, setHasOptInOutCookieValue,
        venueReferrerCode, setVenueReferrerCode,
        openVenueReferrerConfirmDialog, handleOpenVenueReferrerConfirmDialog, handleCancelVenueReferrerConfirmDialog
    };
};