import {PRODUCT_TYPE} from "../../utils/consts";
import {ProductItemType} from "../../typing/types";

export default class ItemsOrderHelper {

    // Sort items in this order:
    // - Collections first by the order of their first item
    // - Then SingleItem given the itemOrders orders
    // - Then all other items not present in itemOrders alphabetically
    static sortItems(itemOrders: {itemUuid: string, order: number}[], products: ProductItemType[]): ProductItemType[]{
        const orderMap = new Map<string, number>();
        itemOrders.forEach(({ itemUuid, order }) => {
            orderMap.set(itemUuid, order);
        });

        function compareProducts(a: ProductItemType, b: ProductItemType): number {
            // Prioritize collections over single items
            if (a.type === PRODUCT_TYPE.COLLECTION && b.type !== PRODUCT_TYPE.COLLECTION) {
                return -1; // Collections come first
            } else if (a.type !== PRODUCT_TYPE.COLLECTION && b.type === PRODUCT_TYPE.COLLECTION) {
                return 1; // Single items come after collections
            }

            // For items of the same type, determine the sort order and Name for sorting
            const getSortInfo = (product: ProductItemType) => {
                let order = Number.MAX_SAFE_INTEGER; // Default high order for items not in itemOrders
                let name = ''; // Default empty Name

                if (product.type === PRODUCT_TYPE.COLLECTION) {
                    const firstItemUuid = product.items[0]?.data.uuid;
                    if (firstItemUuid && orderMap.has(firstItemUuid)) {
                        order = orderMap.get(firstItemUuid) ?? Number.MAX_SAFE_INTEGER
                    }
                    name = product.marketCollection.content.name; // Use collection Name if no items
                } else {
                    if (orderMap.has(product.data.uuid)) {
                        order = orderMap.get(product.data.uuid) ?? Number.MAX_SAFE_INTEGER
                    }
                    name = product.data.name;
                }

                return { order, name };
            };

            const aInfo = getSortInfo(a);
            const bInfo = getSortInfo(b);

            // First sort by order within the same type category
            if (aInfo.order !== bInfo.order) {
                return aInfo.order - bInfo.order;
            }

            // Then sort alphabetically by Name
            return aInfo.name.localeCompare(bInfo.name);
        }
        return products.sort(compareProducts);
    }



    static getItemsOrder(category: string, itemName: string): number {
        switch (category) {
            case "Access Buffet & Contenant":
                switch (itemName) {
                    case "Lot de 3 réhausses carrées en bois": return 1
                    case "Réhausse cube en Bambou naturel": return 2
                    case "Réhausse cube noire en bambou": return 3
                    case "Lot de 5 réhausses gigognes en bois": return 4
                    case "Seau à Champagne Inox": return 5
                    case "Vasque Inox 10L": return 6
                    case "Fontaine à jus 5L": return 7
                    case "Carafe en verre 0.5L": return 8
                    case "Carafe en verre 1L": return 9
                    default: return 99999
                }
            case "Accueil & Conférence":
                switch (itemName) {
                    case "Banque d'accueil Vintage en bois": return 1
                    case "Bar Vintage en bois": return 2
                    case "Bar bois noir ": return 2.1
                    case "Bar Lumineux 16 coloris": return 3
                    case "Banque d'accueil Droit Paris": return 4
                    case "Banque d'accueil Angle Paris": return 5
                    case "Comptoir desk lumineux": return 5.1
                    case "Snack bar slide lumineux": return 5.2
                    case "Bar jumbo corner lumineux": return 5.3
                    case "Bar jumbo lumineux": return 5.4
                    case "Bar wave corner lumineux": return 5.5
                    case "Bar wave Lumineux": return 5.6
                    case "Bar lumineux avec bac à glaçons": return 6
                    case "Comptoir d'accueil noir": return 6.09
                    case "Banque d'accueil blanche": return 6.1
                    case "Pupitre Plexi": return 7
                    case "Pupitre Plexi Z": return 8
                    case "Comptoir kubo blanc plateau bois + facade blanche": return 8.1
                    case "Comptoir kubo noir plateau noir + facade blanche": return 8.2
                    case "Console kubo blanc plateau bois": return 8.3
                    case "Console kubo blanc plateau blanc": return 8.4
                    case "Console kubo blanc plateau noir": return 8.5
                    case "Console kubo noir plateau bois": return 8.6
                    case "Console kubo noir plateau noir": return 8.7
                    case "Console kubo noir plateau blanc": return 8.8
                    case "Tablette écritoire pour chaise Apolline": return 9
                    case "Clip de liaison chaise Apolline": return 10
                    case "Pupitre lumineux slide": return 10.1
                    case "Barre inter-rangée Droite chaise Apolline": return 11
                    case "Barre inter-rangée Gauche chaise Apolline": return 12
                    case "Portant + 50 cintres": return 13
                    case "Potelet noir à sangle": return 14
                    case "Potelet doré": return 15
                    case "Pack de 50 cintres": return 15.1
                    case "Cordon velours avec finition accroche dorée": return 16
                    case "Panneau indicateur pour potelet": return 17
                    case "Présentoir de documents": return 18
                    case "Paperboard effacable et magnétique": return 19
                    case "Recharge de 45 feuilles papier pour paper board": return 20
                    case "Paravent en bambou tressé noir": return 21
                    case "Paravent tréssé beige": return 21.1
                    case "Paravent Canage ": return 21.2
                    case "Paravent Teck H180cm": return 21.3
                    case "Carnet de vestiaire 50 tickets": return 22
                    case "Stèle lumineuse 110cm": return 39
                    case "Colonne Carré lumineuse 80cm ": return 40
                    case "Colonne Carré lumineuse 115cm ": return 40.1
                    case "Colonne Carré lumineuse 170cm ": return 40.2
                    case "Colonne ronde lumineuse 70cm": return 40.3
                    case "Colonne ronde lumineuse 110cm": return 40.4
                    case "Colonne ronde limineuse 170cm": return 40.5
                    case "Bar exotique": return 999999
                    default: return 99999
                }
            case "Animation":
                switch (itemName) {
                    case "Machine Barbe à papa": return 1
                    case "Machine à Hot Dog": return 2
                    case "Plancha éléctrique professionnelle": return 3
                    case "Machine Pop Corn professionnelle": return 4
                    case "Friteuse double 2x8L": return 5
                    case "Fontaine à Chocolat": return 7
                    case "Machine à café Nespresso": return 8
                    case "Crèpière": return 9
                    case "Broyeur à glaçons": return 10
                    case "Machine à glaçons": return 11
                    case "Refrigérateur professionnel 350L": return 40
                    default: return 99999
                }
            case "Assiette":
                switch (itemName) {
                    case "Assiette ronde Filet Or 16cm": return 1
                    case "Assiette ronde Filet Or 21cm": return 2
                    case "Assiette ronde Filet Or 24cm": return 3
                    case "Assiette ronde Filet Or 27cm": return 4
                    case "Assiette ronde Perle blanche 15cm": return 5
                    case "Assiette ronde Perle blanche 20cm": return 6
                    case "Assiette ronde Perle blanche 27cm": return 7
                    case "Assiette creuse ronde Perle blanch 21cm": return 8
                    case "Assiette ronde transparente en Verre 19cm": return 9
                    case "Assiette ronde transparente en Verre 27cm": return 10
                    case "Bol en Verre transparent": return 11
                    case "Assiette carrée Elegance blanche 15cm": return 12
                    case "Assiette presentation carré élégance 28cm": return 12.4
                    case "Assiette carré élégance 24 cm": return 12.5
                    case "Assiette carré creuse élégance 20cm": return 12.6
                    case "Assiette carrée Elegance blanche 20cm": return 13
                    case "Assiette carrée Elegance blanche 26cm": return 14
                    case "Assiette ronde Vintage Fleurie 19/22cm": return 15
                    case "Assiette ronde Vintage Fleurie 24/26cm": return 16
                    case "Assiette ronde Vintage blanche dorée 19/22cm": return 17
                    case "Asssiette ronde Vintage blanche dorée 24/26cm": return 18
                    default: return 99999
                }
            case "Assise Extérieure":
                switch (itemName) {
                    case "Fauteuil extérieur d'angle Bambou coussin écru": return 1
                    case "Fauteuil extérieur Bambou coussin écru": return 2
                    case "Fauteuil extérieur Rotin coussin blanc": return 2.1
                    case "Canapé Bambou avec coussins": return 2.4
                    case "Fauteuil bambou avec coussins": return 2.5
                    case "Fauteuil extérieur Chilienne transat bois tissu blanc": return 3
                    case "Transat chilienne rouge": return 3.1
                    case "Transat chilienne bleu": return 3.2
                    case "Transat chilienne jaune": return 3.3
                    case "Transat chilienne vert": return 3.4
                    case "Transat chilienne rayée blanc et bleu": return 3.5
                    case "Transat chilienne rayée blanc et rouge": return 3.6
                    case "Transat / Chilienne noire": return 3.7
                    case "Banquette palette bleu": return 5.1
                    case "Canapé palette bleu": return 5.11
                    case "Banquette palette gris": return 5.2
                    case "Canapé palette gris": return 5.21
                    case "Banquette palette rouge": return 5.3
                    case "Canapé palette rouge": return 5.31
                    case "Canapé palette bleu turquoise": return 5.32
                    case "Canapé palette vert": return 5.33
                    case "Canapé panama blanc": return 6
                    case "Canapé panama bleu": return 6.1
                    case "Canapé panama fushia": return 6.2
                    case "Canapé panama jaune": return 6.3
                    case "Canapé panama noir": return 6.4
                    case "Canapé panama naturel": return 6.5
                    case "Fauteuil panama Blanc": return 6.6
                    case "Fauteuil panama Bleu": return 6.7
                    case "Fauteuil panama fushia": return 6.8
                    case "Fauteuil panama jaune": return 6.9
                    case "Fauteuil panama Noir": return 6.91
                    case "Fauteuil panama naturel": return 6.92
                    case "Pouf Géant Noir": return 6.93
                    case "Pouf Géant rouge": return 6.94
                    case "Pouf Géant turquoise": return 6.95
                    case "Pouf Géant vert": return 6.96
                    case "Canapé resine assise blanche": return 7
                    case "Canapé resine assise noire": return 7.1
                    case "Fauteuil resine assise blanche": return 7.2
                    case "Fauteuil resine assise noire": return 7.3
                    case "Fauteuil extérieur Lounge noir": return 9
                    case "Fauteuil extérieur Lounge gris anthracite": return 10
                    case "Fauteuil extérieur Lounge blanc": return 11
                    case "Fauteuil extérieur Cosy blanc": return 12
                    case "Salon de jardin Lounge blanc": return 30
                    case "Salon de jardin Lounge gris": return 31
                    case "Salon de jardin Lounge noir": return 32
                    case "Fauteuil extérieur Rotina en rotin pieds noirs": return 9999
                    default: return 99999
                }
            case "Banc & Banquette":
                switch (itemName) {
                    case "Banc Kermesse bois": return 1
                    case "Banc Kermesse blanc": return 2
                    case "Banc bois Massif ": return 3
                    case "Banc flow blanc assise blanche": return 4
                    case "Banc flow blanc assise bois": return 5
                    case "Banc flow blanc assise noire ": return 6
                    case "Banc flow noir assise bois": return 7
                    case "Banc flow noir assise noire ": return 8
                    case "Banquette lounge Simili cuir noire": return 40
                    case "Banquette arc Blanche": return 41
                    case "Banquette Ypsilon blanche": return 42
                    case "Pouf carré XL blanc": return 43
                    case "Pouf rond XL blanc": return 44
                    case "Banquette lounge Simili cuir blanche": return 50
                    case "Banquette arc noire": return 51
                    case "Pouf carré XL noir": return 52
                    case "Pouf Rond XL noir": return 53
                    case "Banquette arc lumineuse": return 60
                    case "Banquette droite lumineuse                ": return 61
                    case "Banquette ypsilon lumineuse": return 62
                    case "Canapé banquette Lounge noire": return 70
                    case "Canapé banquette Lounge gris anthracite": return 71
                    case "Canapé banquette Lounge blanc": return 72
                    default: return 99999
                }
            case "Canapé":
                switch (itemName) {
                    case "Canapé Augustin velours bleu canard 2 places": return 1
                    case "Canapé Augustin velours jaune moutarde 2 places": return 2
                    case "Canapé Augustin velours rose 2 places": return 3
                    case "Canapé Rotin coussin blanc 2 places": return 5
                    case "Canapé Rotina pieds noirs 2 places": return 5.01
                    case "Canapé cannage velours noir": return 5.1
                    case "Canapé Pablo velours bleu": return 6
                    case "Canapé Pablo velours bordeaux": return 6.1
                    case "Canapé Pablo velours jaune": return 6.2
                    case "Canapé Pablo velours noir": return 6.3
                    case "Canapé Pablo velours taupe": return 6.4
                    case "Canapé Pablo velours vert": return 6.5
                    case "Canapé Pia velours bleu": return 7
                    case "Canapé Pia velours jaune": return 7.1
                    case "Canapé Pia velours noir": return 7.2
                    case "Canapé Pia velours rose": return 7.3
                    case "Canapé Pia velours taupe": return 7.4
                    case "Canapé Pia velours vert": return 7.5
                    case "Canapé Chersterfield vieilli simili": return 8
                    case "Canapé club": return 9.1
                    case "Canapé nuage blanc": return 9.5
                    case "Canapé nuage rouge": return 9.6
                    case "Canapé nuage Noir": return 9.7
                    case "Canapé conic corner blanc/blanc": return 10
                    case "Canapé conic corner noir/gris": return 10.1
                    case "Canapé conic corner noir": return 10.2
                    case "Canapé conic blanc": return 11
                    case "Canapé conic blanc/gris": return 11.1
                    case "Canapé conic noir/Gris": return 11.2
                    case "Canapé conic noir": return 11.3
                    case "Canapé/Chauffeuse simili blanc": return 12
                    case "Canapé / Chauffeuse simili Noir": return 12.1
                    case "Canapé Dolce velours nude 2-3 places": return 14
                    case "Canapé Dolce velours rose 2-3 places": return 14.1
                    case "Canapé Scandinave bleu pétrole": return 16
                    case "Canapé Scandinave gris": return 16.1
                    default: return 99999
                }
            case "Chaise & Tabouret Haut":
                switch (itemName) {
                    case "Tabouret haut Kubo blanc avec galette blanche": return 3
                    case "Tabouret haut Kubo blanc avec galette bois": return 4
                    case "Tabouret haut Kubo blanc avec galette noire": return 5
                    case "Tabouret haut Kubo noir avec galette blanche": return 6
                    case "Tabouret haut Kubo noir avec galette bois": return 7
                    case "Tabouret haut Kubo noir avec galette noire": return 8
                    case "Tabouret Haut Design transparent": return 9
                    case "Tabouret Design blanc": return 9.1
                    case "Tabouret Design rouge": return 9.2
                    case "Chaise haute Clara blanche": return 10
                    case "Chaise haute Clara noire": return 11
                    case "Chaise haute Kasar blanche": return 11.1
                    case "Chaise haute Kasar Noire": return 11.2
                    case "Tabouret liberty Orange": return 15.1
                    case "Tabouret liberty rouge": return 15.2
                    case "Chaise haute manhattan assise blanche": return 22
                    case "Chaise haute manhattan assise noire": return 23
                    case "Tabouret tolix gris métalisé ": return 24
                    case "Tabouret tolix blanc": return 24.1
                    case "Tabouret tolix noir": return 24.2
                    case "Tabouret tolix rouge ": return 24.3
                    case "Tabouret tolix rose pastel": return 24.4
                    case "Tabouret tolix vert menthe": return 24.5
                    case "Chaise haute scandinave blanche": return 25
                    case "Chaise haute scandinave noire": return 26
                    case "Chaise haute steel noire": return 30
                    case "Tabouret Ours fourrure blanc": return 40
                    case "Tabourte Ours fourrure Brun": return 41
                    case "Tabouret en bois": return 43
                    case "Tabouret haut lumineux": return 50
                    default: return 99999
                }
            case "Chaise Empilable":
                switch (itemName) {
                    case "Chaise Napoléon cristal avec galette blanche": return 1
                    case "Chaise Napoléon cristal avec galette noire": return 1.1
                    case "Chaise Napoléon cristal avec galette velours bordeaux": return 1.2
                    case "Chaise Napoléon blanche avec galette blanche": return 2
                    case "Chaise Napoléon blanche avec galette noire": return 2.1
                    case "Chaise Napoléon blanche avec galette velours bordeaux": return 2.2
                    case "Chaise Napoléon dorée avec galette blanche": return 3
                    case "Chaise Napoléon dorée avec galette noire": return 3.1
                    case "Chaise Napoléon dorée avec galette velours bordeaux": return 3.2
                    case "Chaise napoléon noire assise blanche": return 4
                    case "Chaise napoléon noire assise noire": return 4.1
                    case "Chaise napoléon noire assise rouge": return 4.2
                    case "Chaise Cross back assise rotin": return 5
                    case "Chaise Chivari bambou avec galette blanche": return 6
                    case "Chaise Médaillon bois": return 7
                    case "Chaise Chivari blanche avec galette blanche": return 8
                    case "Chaise victoria transparente": return 9
                    case "Chaise victoria noire": return 9.1
                    case "Chaise victoria rouge": return 9.2
                    case "Chaise Trix blanche": return 10
                    case "Chaise Trix noire": return 11
                    case "Chaise kasar blanche": return 12
                    case "Chaise kasar noire": return 12.1
                    case "Chaise Contemporaine blanche": return 14
                    case "Chaise Contemporaine crystal": return 14.1
                    case "Chaise Contemporaine noire": return 14.2
                    case "Chaise blanche master": return 15
                    case "Chaise scandinave blanche": return 16.1
                    case "Chaise scandinave grise": return 16.2
                    case "Chaise scandinave noire": return 16.3
                    case "Chaise Tolix métal gris": return 17
                    case "Chaise Tolix métal jaune": return 17.1
                    case "Chaise tolix gris métalisé vieilli": return 17.2
                    case "Chaise tolix noire": return 17.3
                    case "Chaise Tolix métal rouge": return 17.4
                    case "Chaise tolix rose pastel": return 17.5
                    case "Chaise Tolix métal vert": return 17.5
                    case "Chaise tolix blanche": return 17.8
                    case "CrossBack alu bleu": return 19
                    case "Crossback alu jaune": return 19.1
                    case "CrossBack alu verte": return 19.2
                    case "Chaise Bistrot noire dorée": return 20
                    case "Chaise retro noir et canage": return 21
                    case "Chaise Fantôme blanche": return 30
                    case "Chaise Miami blanche": return 40
                    default: return 99999
                }
            case "Chaise Pliante":
                switch (itemName) {
                    case "Chaise pliante Apolline argent assise velours noir": return 1
                    case "Chaise pliante Apolline argent assise velours noir avec tablette": return 1.1
                    case "Chaise pliante Apolline bronze assise velours rouge": return 2
                    case "Chaise pliante Léon blanche": return 3
                    case "Chaise pliante Léon noire": return 4
                    case "Chaise pliante Victor blanche": return 4.1
                    case "Chaise pliante Victor noire": return 4.2
                    case "Chaise pliante Square blanche": return 5
                    case "Chaise pliante Square bois": return 6
                    case "Chaise pliante Square jaune": return 7
                    case "Chaise pliante Square orange": return 8
                    case "Chaise pliante Square verte": return 9
                    case "Chaise Réalisateur noire": return 10
                    case "Chaise Réalisateur noire et bois": return 10.1
                    default: return 99999
                }
            case "Chauffage":
                switch (itemName) {
                    case "Parasol chauffant au Gaz": return 1
                    case "Parasol chauffant flamme design": return 1.1
                    case "Parasol chauffant Electrique": return 2
                    case "Chauffage extérieur à Flamme au Gaz": return 3
                    case "Barrière blanche en Bois": return 999998
                    case "Cendrier poubelle inox ": return 999999
                    default: return 99999
                }
            case "Couvert":
                switch (itemName) {
                    case "Fourchette de table Dorée": return 1
                    case "Couteau de table Doré": return 2
                    case "Cuillère à café Dorée": return 3
                    case "Cuillère de table Dorée": return 4
                    case "Fourchette de table en Inox": return 5
                    case "Couteau de table en Inox": return 6
                    case "Cuillère à café en Inox": return 7
                    case "Cuillère de table en Inox": return 8
                    case "Fourchette à entremet en Inox": return 9
                    case "Couteau à entremet en Inox": return 10
                    case "Cuillère à entremet en Inox": return 11
                    case "Cuillère moka en Inox": return 12
                    case "Fourchette coupante/à huître en Inox": return 13
                    case "Fourchette moka en Inox": return 14
                    default: return 99999
                }
            case "Cuisine":
                switch (itemName) {
                    case "Etuve ventillée 10 plats + grilles": return 1
                    case "Etuve ventilée 20 plats + grilles": return 2
                    case "Echelle 20 plats": return 3
                    case "Porte-assiettes à tétons pour 84 assiettes": return 4
                    case "Grande bouilloire 20 L": return 5
                    case "Percolateur à café pro en Inox 6L": return 6
                    case "Percolateur à café pro en Inox 15L": return 7
                    case "Chafing Dish": return 8
                    case "Bac gastro Inox 1/1": return 9
                    case "Thermos 1.5L en Inox": return 10
                    case "Thermos 3L en Inox": return 11
                    case "Mat d'éclairage led pour buffet": return 12
                    case "Cuillère service perforée pour glace": return 13
                    case "Cuillère de service en Inox": return 14
                    case "Louche de service Inox": return 15
                    case "Pelle à tarte Inox": return 16
                    case "Corbeille à pain": return 17
                    case "Saladier Perle blanc 25cm": return 18
                    case "Salière et poivrière": return 19
                    case "Pince Inox pour service": return 20
                    case "Poubelle plastique noire 70L": return 21
                    default: return 99999
                }
            case "Divers & Consommable":
                switch (itemName) {
                    case "Parasol bois exotique et blanc avec socle": return 1
                    case "Parasol carré gris anthracite avec socle alu": return 2
                    case "Chevalet blanc": return 3
                    case "Chevalet bois nature": return 3.1
                    case "Cendrier sur pied noir": return 4
                    case "Cendrier sur pied Inox": return 5
                    case "Kit 4 feutres effaçables + éponge pour paper board": return 6
                    case "Borne distributeur de gel hydroalcoolique sans contact": return 7
                    case "Plante artificelle Bambou en pot": return 7
                    case "Boule Lumineuse autonome": return 8
                    case "Rouleau de coton gratté noir": return 8
                    case "Pupitre plexiglass de conférence col de cygne": return 8.1
                    case "Etui de 10 capsules Nespresso": return 9
                    case "Valise maquillage éclairée": return 999990
                    case "Boule lumineuse 30cm": return 999991
                    case "Boule lumineuse 50cm": return 999992
                    case "Lampadaire  lumineux slide": return 999993
                    case "Tour eiffel lumineuse": return 999994
                    case "Corbeille blanche à papier": return 999998
                    case "Miroir sur pied noir": return 999999
                    case "Corbeille noire à papier": return 999999
                    default: return 99999
                }
            case "Eclairage":
                switch (itemName) {
                    case "Projecteur autonome Box Kolor avec wifi 10h sur batterie": return 1
                    case "Guirlande Guingette 10m": return 2
                    case "Machine Sparkular à étincelles froides": return 3
                    default: return 99999
                }
            case "Fauteuil":
                switch (itemName) {
                    case "Fauteuil Cannage doudou blanc": return 1
                    case "Fauteuil Cannage velours jaune": return 2
                    case "Fauteuil Cannage velours noire": return 3
                    case "Fauteuil Bouclette blanc": return 4
                    case "Fauteuil Marceau bouclé blanc ": return 5
                    case "Fauteuil Emmanuelle rotin coussin blanc": return 6
                    case "Fauteuil Ernest bleu pétrole": return 7
                    case "Fauteuil Ernest gris anthracite": return 8
                    case "Fauteuil Ernest jaune": return 9
                    case "Fauteuil Axel velours noir ": return 10
                    case "Fauteuil Axel velours rose": return 10.1
                    case "Fauteuil Axel velours taupe": return 10.2
                    case "Fauteuil Axel velours vert": return 10.3
                    case "Fauteuil Clhoe Velours noir": return 11
                    case "Fauteuil Clhoe Velours Rose": return 11.1
                    case "Fauteuil Clhoe Velours taupe": return 11.2
                    case "Fauteuil Ella velours bleu et pied doré": return 12
                    case "Fauteuil Ella velours gris et pied doré": return 12.1
                    case "Fauteuil Ella velours noir et pied doré": return 12.2
                    case "Fauteuil Pablo velours bleu": return 13
                    case "Fauteuil Pablo velours bordeaux": return 13.1
                    case "Fauteuil Pablo velours jaune": return 13.2
                    case "Fauteuil Pablo velours noir": return 13.3
                    case "Fauteuil Pablo velours taupe": return 13.4
                    case "Fauteuil Pablo velours vert": return 13.5
                    case "Fauteuil Pia velours bleu": return 16
                    case "Fauteuil Pia velours noir": return 16.1
                    case "Fauteuil Pia velours rose": return 16.2
                    case "Fauteuil Pia velours taupe": return 16.3
                    case "Fauteuil Pia velours vert": return 16.4
                    case "Fauteuil nuage blanc": return 17
                    case "Fauteuil nuage Bleu": return 17.1
                    case "Fauteuil nuage Noir": return 17.2
                    case "Fauteuil nuage rouge": return 17.3
                    case "Fauteuil Oeuf gris": return 18
                    case "Fauteuil Oeuf rouge": return 18.1
                    case "Fauteuil Feuille vert": return 19
                    case "Fauteuil Feuille Violet": return 19.1
                    case "Fauteuil club": return 20
                    case "Fauteuil club simili cuir blanc": return 20.1
                    case "Fauteuil club simili cuir noir": return 20.2
                    case "Fauteuil conic blanc": return 30
                    case "Fauteuil conic blanc/gris": return 30.1
                    case "Fauteuil conic noir/gris": return 30.2
                    case "Fauteuil conic noir": return 30.3
                    case "Fauteuil lumineux ": return 40
                    case "Fauteuil/trone de père noel": return 70
                    default: return 99999
                }
            case "Glace":
                switch (itemName) {
                    case "Bac en Plastique": return 1
                    case "Sac de glaçon cube 20kg": return 2
                    case "Sac de glace pilée 20kg": return 3
                    default: return 99999
                }
            case "Housse":
                switch (itemName) {
                    case "Housse lycra Chaise Universelle blanche": return 7
                    case "Housse lycra Galette Napoléon couleur": return 8
                    default: return 99999
                }
            case "Nappe":
                switch (itemName) {
                    case "Nappe carrée blanche 150cm en coton": return 1
                    case "Nappe carrée blanche 210cm en coton": return 2
                    case "Nappe carrée blanche 240cm en coton": return 3
                    case "Nappe carrée écru 300cm en lin": return 4
                    case "Nappe rectangulaire blanche 175x300cm en coton": return 5
                    case "Nappe rectangulaire blanche 200*300cm en coton": return 6
                    case "Nappe rectangulaire blanche 400*210cm en coton": return 7
                    case "Nappe rectangulaire blanche 500*210cm en coton": return 8
                    case "Nappe rectangulaire blanche 600*210cm en coton": return 9
                    case "Nappe rectangulaire blanche 800*210cm en coton": return 10
                    case "Nappe rectangulaire noire 300*175cm": return 11
                    case "Nappe ronde blanche diamètre 280cm": return 12
                    case "Nappe ronde blanche diamètre 300cm": return 13
                    case "Nappe ronde noire diamètre 280cm": return 14
                    case "Nappe ronde rose diamètre 280cm": return 15
                    default: return 99999
                }
            case "Plateau":
                switch (itemName) {
                    case "Plateau rectangulaire Bambou noir": return 1
                    case "Plateau rectangulaire Bambou": return 2
                    case "Plateau rectangulaire Ardoise": return 3
                    case "Plateau carré Ardoise": return 4
                    case "Plateau ovale en Inox ": return 5
                    case "Plateau rectangulaire en Inox": return 6
                    case "Plateau rond Limonadier noir": return 7
                    default: return 99999
                }
            case "Pouf":
                switch (itemName) {
                    case "Pouf lounge Simili cuir blanc": return 1
                    case "Pouf lounge Simili cuir noir": return 2
                    case "Pouf cube Lumineux": return 3
                    case "Pouf Velours rose et doré": return 4
                    case "Pouf Velours vert et doré": return 5
                    case "Pouf extérieur Bambou coussin écru": return 6
                    case "Pouf Ours fourrure blanc": return 7
                    case "Pouf Ours fourrure Gris": return 8
                    case "Pouf kubo blanc assise blanche": return 9
                    case "Pouf kubo blanc assise bois": return 9.1
                    case "Pouf kubo blanc assise noire": return 9.2
                    case "Pouf kubo noire assise bois": return 9.3
                    case "Pouf kubo noire assise noire": return 9.4
                    case "Pouf Lem blanc": return 10
                    case "Pouf Lem Noir": return 10.1
                    default: return 99999
                }
            case "Scène":
                switch (itemName) {
                    case "Scène praticable 40cm de hauteur ": return 1
                    case "Scène praticable 20 cm de hauteur": return 2
                    case "6 plateaux 1*1m + 6 supports en alu hauteur 40cm": return 3
                    case "Marche pour scène": return 4
                    default: return 99999
                }
            case "Serviette":
                switch (itemName) {
                    case "Serviette carrée blanche 50cm en coton": return 1
                    default: return 99999
                }
            case "Sonorisation":
                switch (itemName) {
                    case "Enceinte amplifiée 800KW-K10-QSC": return 1
                    case "Paire de trépieds pour enceinte": return 2
                    case "Micro professionnel sans fil": return 3
                    case "Pack son autonome 0-50 personnes ": return 4
                    case "Pack son autonome 0-50 personnes avec montage & démontage": return 4.01
                    case "Pack son autonome 0-150 personnes": return 4.1
                    case "Pack son premium avec technicien": return 4.2
                    default: return 99999
                }
            case "Table Basse":
                switch (itemName) {
                    case "Table basse ronde blanche Design": return 1
                    case "Table basse ronde noire Design": return 2
                    case "Table basse Lem Blanche": return 3
                    case "Table basse Lem noire": return 4
                    case "Table basse ronde Modulo blanche": return 5
                    case "Table basse ronde Modulo noire": return 6
                    case "Table basse carré modulo": return 6.1
                    case "Table basse carré Linea blanche plateau blanc": return 7
                    case "Table basse Linea blanche plateau bois": return 7.1
                    case "Table basse carré Linea blanche plateau noire": return 7.2
                    case "Table basse carré Linea noire plateau blanc": return 8
                    case "Table basse carré Linea noire plateau bois": return 8.1
                    case "Table basse carré Linea noire plateau noire": return 8.2
                    case "Table basse carrée bois Massif": return 9
                    case "Table basse rectangulaire bois Massif": return 10
                    case "Table basse palette": return 10.1
                    case "Table basse ronde Rotin": return 10.2
                    case "Table basse New town": return 11
                    case "Table basse ronde Linea blanche plateau blanc": return 12
                    case "Table basse ronde Linea blanche plateau bois": return 12.1
                    case "Table basse ronde Linea blanche plateau noire": return 12.2
                    case "Table basse ronde Linea noire plateau blanc": return 12.3
                    case "Table basse ronde Linea noire plateau bois": return 13
                    case "Table basse ronde Linea noire plateau noire": return 13.1
                    case "Table basse lumineuse ronde XL": return 13.2
                    case "Table basse conic  blanche": return 14
                    case "Table basse conic lumineuse": return 14.1
                    case "Table basse conic  noire": return 14.2
                    case "Table basse carrée Basic blanche": return 15
                    case "Table basse carrée Basic noire": return 15.1
                    case "table basse Queen Blanche": return 16
                    case "table basse Queen transparente": return 16.1
                    case "Table basse rectangulaire Lounge blanche": return 17
                    case "Table basse rectangulaire Lounge grise": return 17.1
                    case "Table basse rectangulaire Lounge noire": return 17.2
                    case "Table basse gigogne Bergen Bois": return 19
                    case "Table basse gigogne Bergen Blanche": return 19.1
                    case "Table basse Galet blanche": return 19.2
                    default: return 99999
                }
            case "Table Buffet":
                switch (itemName) {
                    case "Table buffet rectangulaire Box": return 1
                    case "Table buffet rectangulaire Box houssée blanche": return 2
                    case "Table buffet rectangulaire Box houssée noire": return 3
                    case "Table buffet carré Box": return 4
                    case "Table buffet carrée Box houssée blanche": return 5
                    case "Table buffet carrée Box houssée noire": return 6
                    case "Table buffet rectangulaire Box Lumineux": return 7
                    case "Table buffet rectangulaire houssée noire": return 8
                    case "Table buffet rectangulaire houssée blanche": return 9
                    case "Table buffet rectangulaire Classique": return 10
                    case "Table buffet rectangulaire Classique Bois": return 11
                    case "Table buffet bois Massif": return 12
                    case "Buffet kube box lumineux": return 999999
                    default: return 99999
                }
            case "Table Guéridon":
                switch (itemName) {
                    case "Table guéridon ronde Modulo noire ": return 1
                    case "Table guéridon ronde Modulo blanche": return 2
                    case "Gueridon Bistrot marbre ": return 2.1
                    case "Table gueridon carrée bistrot noire": return 3
                    case "Table guéridon carrée Square bois": return 4
                    case "Table guéridon carrée Square bois et métal": return 5
                    case "Table guéridon ronde Square blanche": return 6
                    case "Table guéridon ronde Bois à napper 2 personnes": return 7
                    case "Table guéridon ronde Bois à napper 4 personnes": return 8
                    default: return 99999
                }
            case "Table Haute & Mange Debout":
                switch (itemName) {
                    case "Table mange debout carrée Kubo blanc plateau blanc": return 1
                    case "Table mange debout carrée Kubo blanc plateau bois": return 2
                    case "Table mange debout carrée Kubo blanc plateau noir": return 3
                    case "Table mange debout carrée Kubo noir plateau blanc": return 4
                    case "Table mange debout carrée Kubo noir plateau bois": return 5
                    case "Table mange debout carrée Kubo noir plateau noir": return 6
                    case "Table mange debout ronde Modulo blanc": return 7
                    case "Table mange debout ronde Modulo noir": return 8
                    case "Table mange debout ronde houssée noire": return 9
                    case "Table Haute & Mange Debout": return 10
                    case "Table mange debout ronde Classique": return 11
                    case "Mange debout liberty blanc": return 11.1
                    case "Mange debout carré linéa blanche plateau blanc": return 12
                    case "Table mange debout ronde Aluminium": return 12
                    case "Mange debout carré linéa blanche plateau bois": return 12.1
                    case "Mange debout carré linéa blanche plateau noire": return 12.2
                    case "Mange debout carré linéa noir plateau blanc": return 12.3
                    case "Mange debout carré linéa noir plateau bois": return 12.4
                    case "Mange debout carré linéa noir plateau noire": return 12.5
                    case "Mange debout kubo curve blanc plateau blanc": return 12.6
                    case "Mange debout kubo curve blanc plateau bois": return 12.7
                    case "Mange debout kubo curve blanc plateau noir": return 12.8
                    case "Mange debout kubo curve noir plateau blanc": return 12.9
                    case "Mange debout kubo curve noir plateau bois": return 13
                    case "Mange debout kubo curve noir plateau noir": return 13.1
                    case "Mange debout rond linéa blanche plateau blanc": return 13.2
                    case "Mange debout rond linéa blanche plateau bois": return 13.3
                    case "Mange debout rond linéa blanche plateau noire": return 13.4
                    case "Mange debout rond linéa noir plateau blanc": return 13.5
                    case "Mange debout rond linéa noir plateau bois": return 13.6
                    case "Mange debout rond linéa noir plateau noire": return 13.7
                    case "Mange debout lumineux": return 14
                    case "Mange debout conic blanc": return 19
                    case "Mange debout conic noir": return 19.1
                    case "Mange debout Peak lumineuse Slide": return 20
                    case "Table haute kubo blanche plateau blanc": return 21
                    case "Table haute kubo blanche plateau noir": return 22
                    case "Table haute kubo blanche plateau bois": return 23
                    case "Tables haute kubo noire plateau blanc": return 24
                    case "Tables haute kubo noire plateau bois": return 25
                    case "Tables haute kubo noire plateau noir": return 26
                    case "Table Haute Peak Lumineuse": return 27
                    case "Mange debout marbre": return 40
                    case "Table mange debout ronde Tonneau à vin": return 99999
                    case "Mange debout lumineux ": return 999999
                    default: return 99999
                }
            case "Table Honneur":
                switch (itemName) {
                    case "Table d'honneur carrée en bois": return 1
                    case "Table d'honneur demi-lune en bois ": return 2
                    case "Table d'honneur ovale 10 personnes": return 3
                    case "Table d'honneur ovale 14 personnes": return 4
                    case "Table d'honneur ovale 18 personnes": return 5
                    case "Table d'honneur ovale 22 personnes": return 6
                    default: return 99999
                }
            case "Table Rectangulaire":
                switch (itemName) {
                    case "Table rectangulaire 6-8 personnes ": return 1
                    case "Table rectangulaire 8-10 personnes 200cm": return 2
                    case "Table rectangulaire 8-10 personnes 244cm": return 3
                    case "Table carrée 2-4 personnes": return 4
                    case "Table rectangulaire bois Massif": return 5
                    case "Table rectangulaire Kermesse bois": return 6
                    case "Table brasserie blanche": return 6.1
                    case "Table rectangulaire 6-8 personnes houssée noire": return 7
                    case "Table rectangulaire 6-8 personnes houssée blanche": return 8
                    default: return 99999
                }
            case "Table Ronde":
                switch (itemName) {
                    case "Table ronde 6-8 personnes": return 1
                    case "Table ronde 8-10 personnes": return 2
                    case "Table ronde 10-12 personnes": return 3
                    case "Table ronde bois Massif 8-10 personnes": return 4
                    default: return 99999
                }
            case "Tente":
                switch (itemName) {
                    case "Tente pagode": return 1
                    case "Tente structure aluminium avec bâches blanches": return 2
                    case "Tente stretch": return 3
                    case "Tente Bambou": return 4
                    case "Plancher K7 sur structure aluminium autoportée pour tente": return 5
                    default: return 99999
                }
            case "Tente Pliante":
                switch (itemName) {
                    case "Tente pliante 9m2": return 1
                    case "Tente pliante 24m2": return 2
                    case "Bâche mur tente pliante 3*3m": return 3
                    case "Bâche mur tente pliante 4*4m": return 4
                    case "Bâche mur tente pliante 6*4m": return 5
                    case "Leste tente 15kg": return 6
                    case "Tente pliante 3/2": return 7
                    case "Tente pliante 4/4": return 8
                    case "Tente pliante  5 x 5 ": return 9
                    case "Tente pliante noire 3/3": return 10
                    case "Tente pliante noire 4/4": return 11
                    default: return 99999
                }
            case "Verrerie":
                switch (itemName) {
                    case "Verre Cristal à Whisky/Cocktail 30cl": return 1
                    case "Verre Cristal à Whisky/Cocktail 38cl": return 2
                    case "Verre Cristal à vin 21cl": return 3
                    case "Verre Cristal à eau 24cl": return 4
                    case "Flûte Cristal 14cl": return 5
                    case "Coupe Cristal à champagne/cocktail 25cl": return 6
                    case "Carafe Cristal Arrondie 90cl": return 7
                    case "Carafe Cristal Carrée 75cl": return 8
                    case "Verre Diamant bleu à eau 35cl": return 9
                    case "Verre Diamant rose à eau 35cl": return 10
                    case "Verre Magnificence à vin 33cl": return 11
                    case "Verre Magnificence à eau 41cl": return 12
                    case "Flûte Magnificence 24cl": return 13
                    case "Gobelet Magnificence 36cl": return 14
                    case "Verre Régani à vin 19cl": return 15
                    case "Verre vin Open-Up 27 cl": return 15.1
                    case "Verre eau Open-Up 40 cl": return 15.2
                    case "Verre eau Open-Up 37 cl": return 15.3
                    case "Flute Champagne Open-Up 20cl": return 15.4
                    case "Verre Gobelet Open-up bas 38cl": return 15.5
                    case "Verre Gobelet Open-up haut 35cl": return 15.6
                    case "Verre Régani à eau 25cl": return 16
                    case "Flûte Regani 17cl": return 17
                    case "Verre Regani Tubo 22cl": return 18
                    case "Verre Régani Mojito/Biere 35cl": return 19
                    case "Verre Gobelet Cuivre 35 cl": return 19.1
                    case "Verre Gobelet Or 35 cl": return 19.2
                    case "Verre Gobelet Silver 35 cl": return 19.3
                    case "Verre Bodega 20cl": return 20
                    case "Verre cocktail 15cl": return 20.1
                    case "Tasse à café/expresso transparente 8cl": return 21
                    case "Tasse thé/café Perle avec sous-coupe 20cl": return 21
                    case "Tasse à café/expresso Perle avec sous-coupe 9cl": return 23
                    default: return 99999
                }
            case "Vidéo":
                switch (itemName) {
                    case "Ecran toile 3*2.25m avec bord noir 10cm": return 1
                    case "Ecran toile 4*3m avec bord noir 10 cm": return 2
                    case "Kit écran plat sur pied": return 3
                    case "Vidéo projecteur Sony VPL-CW255 4500 lumens": return 4
                    case "Pack vidéo autonome": return 5.1
                    case "Pack vidéo autonome avec montage & démontage": return 5.11
                    case "Pack video autonome +": return 5.2
                    case "Pack vidéo premium avec technicien": return 5.3
                    case "Pack son/vidéo premium": return 5.4
                    case "Pack vidéo - Kit écran plat sur pied": return 6
                    case "Hub Tactile Adok": return 7
                    case "Pack Hub Tactile Adok + Table": return 7.1
                    default: return 99999
                }
            default:
                return 99999

        }
    }
}