import { createTheme, Theme } from '@mui/material/styles'
import { error, grey, primary, secondary, success, warning } from './themeColors'

const fontSize = 14

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

export const RequiredTopForStickyBlock = '80px' // HeaderHeight + 16px as padding. This the required top you will need for a "sticky" block. This is required because the position of the header is "fixed"

// Create a theme instance.
const sedeoTheme = createTheme({
  palette: {
    primary: {
      ...primary,
      light: primary[100],
    },
    secondary,
    error,
    warning,
    success,
    text: {
      primary: '#4C4C4C',
      secondary: '#555555',
      disabled: '#C4C4C4',
    },
    divider: grey[200],
    grey: { ...grey },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontSize,
    htmlFontSize: 16,
    body1: { fontSize },
    body2: { fontSize },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: false,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ul: {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
        p: {
          lineHeight: 1.75,
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        button: {
          fontSize,
        },
        '.MuiRating-sizeSmall': {
          fontSize: '20px',
        },
        '#nprogress .bar': {
          position: 'fixed',
          top: 0,
          left: 0,
          height: '3px !important',
          borderRadius: '0px 300px 300px 0px !important',
          zIndex: 1031,
          background: `${primary[500]} !important`,
          overflow: 'hidden',
        },
        '#nprogress .peg': {
          boxShadow: `0 0 10px ${primary[500]}, 0 0 5px ${primary[500]} !important`,
        },
      },
    },
    MuiOutlinedInput:{
      styleOverrides: {
        root: {
          borderRadius: '25px',
        }
      }
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'capitalize',
          minWidth: 0,
          minHeight: 0,
        },
      },
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
  },
})

const theme = { ...customTheme, ...sedeoTheme }

theme.shadows[1] = '0px 1px 3px rgba(3, 0, 71, 0.09)'
theme.shadows[2] = '0px 4px 16px rgba(43, 52, 69, 0.1)'
theme.shadows[3] = '0px 8px 45px rgba(3, 0, 71, 0.09)'
theme.shadows[4] = '0px 0px 28px rgba(3, 0, 71, 0.01)'

export type MuiThemeProps = Theme

export default theme
